import React from 'react';
import { Grid } from 'semantic-ui-react';
import Details from './Details';
import Owners from './Owners';
import AdvancedSettings from './AdvancedSettings';
import Activity from './Activity';
import CompleteProjectButton from '../CompleteProjectButton';

const ViewProject = ({ project }) => (
  <Grid id="view-project">
    <Grid.Column>
      <Grid.Row className="actions">
        <CompleteProjectButton project={project} />
      </Grid.Row>
      <Grid.Row>
        <Details project={project} />
      </Grid.Row>
      <Grid.Row>
        <Owners project={project} />
      </Grid.Row>
      <Grid.Row>
        <AdvancedSettings project={project} />
      </Grid.Row>
      <Grid.Row>
        <Activity project={project} />
      </Grid.Row>
    </Grid.Column>
  </Grid>
);

export default ViewProject;
