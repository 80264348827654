import { gql } from '@apollo/client';

export const EXTERNAL_ACCOUNT_BASIC_DATA = `
  id
  status
  provider
  accountId
  subscribedCalendars {
    calendarId
    name
  }
`;

const EXTERNAL_ACCOUNT = gql`
  query ExternalAccount($id: ID!) {
    externalAccount(id: $id) {
      ${EXTERNAL_ACCOUNT_BASIC_DATA}
    }
  }
`;

export const EXTERNAL_ACCOUNT_CALENDARS = gql`
  query ExternalAccount($id: ID!) {
    externalAccount(id: $id) {
      id
      calendars {
        calendarId
        name
      }
    }
  }
`;

export const UPDATE_EXTERNAL_CREDENTIAL = gql`
  mutation UpdateExternalCredential ($provider: String!, $credential: String!) {
    updateExternalCredential (provider: $provider, credential: $credential) {
      id
      externalAccounts {
        ${EXTERNAL_ACCOUNT_BASIC_DATA}
      }
    }
  }
`;

export const UPDATE_EXTERNAL_CALENDAR_SUBSCRIPTION = gql`
  mutation UpdateExternalCalendarSubscription ($externalAccountId: ID!, $calendarIds: [String]!) {
    updateExternalCalendarSubscription (externalAccountId: $externalAccountId, calendarIds: $calendarIds) {
      ${EXTERNAL_ACCOUNT_BASIC_DATA}
    }
  }
`;

export const DELETE_EXTERNAL_ACCOUNT = gql`
  mutation DeleteExternalAccount($id: ID!) {
    deleteExternalAccount(id: $id) {
      id
    }
  }
`;

export default EXTERNAL_ACCOUNT;
