import { atcb_action as Atcb } from 'add-to-calendar-button';
import momentTZ from 'moment-timezone';
import 'add-to-calendar-button/assets/css/atcb.css';

const addToCalendar = ({ user, job }) => {
  const meetingAt = momentTZ.tz(job.meetingAt, user.timeZone);
  const separator = '-'.repeat(20);
  const icsContent = {
    name: `${job.project.title} - ${job.user.fullName}`,
    description: `Expert Info: ${job.user.fullName}, ${job.expert.title} at ${job.expert.organization.name}\nProject Info: ${job.project.title}\n${job.project.preInterviewLink ? `\nPre-interview material is available at ${job.project.preInterviewLink}` : ''}${job.qualificationResponses ? `\n\n${job.qualificationResponses.map((el) => `${el.question}: ${el.response.map((res) => `-${res}\n`)}`).join(separator)}` : ''}\n${separator}\n\n${job.meetingInvite}`,
    organizer: 'Dexter|help@dexterexperts.com',
    startDate: meetingAt.format('YYYY-MM-DD'),
    startTime: meetingAt.format('HH:mm'),
    endTime: meetingAt.add(1, 'hour').format('HH:mm'),
    timeZone: user.timeZone,
    location: job.meetingLink,
    options: ['Apple', 'Google', 'iCal', 'Microsoft365', 'Outlook.com', 'Yahoo'],
    duration: '1H',
    trigger: 'click',
    iCalFileName: `Dexter-${job.user.fullName}`,
  };

  return Atcb(icsContent);
};
export default addToCalendar;
