import React, { useEffect, useState } from 'react';
import {
  Form,
  Icon, Button, Divider,
  AccordionTitle,
  AccordionContent,
  Accordion,
  Header,
} from 'semantic-ui-react';
import QuestionForm from './QuestionForm';

export const QUESTIONS_DEFAULT = [{
  id: new Date().getTime(),
  question: '',
  questionType: 'multipleChoice',
  answerChoices: new Array(3).fill(''),
  otherIncluded: false,
},
{
  id: new Date().getTime() + 100,
  question: '',
  questionType: 'knockout',
},
];
const QUALIFICATION_QUESTIONS_MAX_LENGTH = 10;

const QualificationQuestions = ({ project, updateProject }) => {
  const { qualificationQuestions } = project;
  const questionTypes = [
    {
      type: 'knockout',
      activeAccordion: 0,
      subheader: 'Provide any knockout questions for the project. If a candidate answers  "No" to any of these questions,they will be immediately disqualified from the project.',
      header: 'Knockout Questions',
      newQuestion: {
        id: new Date().getTime(),
        question: '',
        questionType: 'knockout',
      },
    }, {
      type: 'supplementary',
      activeAccordion: 1,
      subheader: 'Provide any supplementary questions for the project.',
      header: 'Supplementary Questions',
      newQuestion: {
        id: new Date().getTime(),
        question: '',
        questionType: 'checkbox',
        answerChoices: new Array(3).fill(''),
        otherIncluded: false,
      },
    },
  ];
  const [activeAccordions, setActiveAccordions] = useState([0]);
  useEffect(() => {
    if (qualificationQuestions.length === 0) {
      updateProject({ qualificationQuestions: QUESTIONS_DEFAULT });
    }
  }, [qualificationQuestions.length]);

  const toggleAccordion = (e, titleProps) => {
    const { index } = titleProps;
    const newAccordions = activeAccordions.includes(index)
      ? activeAccordions.filter((item) => item !== index)
      : [...activeAccordions, index];
    setActiveAccordions(newAccordions);
  };
  const knockoutQuestions = qualificationQuestions.filter((q) => q.questionType === 'knockout');
  const supplementaryQuestions = qualificationQuestions.filter((q) => q.questionType !== 'knockout');
  return (
    <div>
      <div>
        <strong className="project-title">
          Qualification Questions (Up to
          {QUALIFICATION_QUESTIONS_MAX_LENGTH}
          ; Optional)
        </strong>
      </div>
      <Divider />
      <Header as="h5">
        Add up to
        {QUALIFICATION_QUESTIONS_MAX_LENGTH}
        {' '}
        qualification questions to help determine the candidate&apos;s fit for the project.
        All candidates will be required to answer all questions before enrolling in the project.
        {' '}
      </Header>
      <Form.Field id="qualification-questions">
        {questionTypes.map((el) => (
          <Accordion key={el.type}>
            <AccordionTitle
              active={activeAccordions.includes(el.activeAccordion)}
              index={el.activeAccordion}
              onClick={toggleAccordion}
              className="qualification-question-header"
            >
              {el.header}
              <Icon name="dropdown" />
            </AccordionTitle>
            <AccordionContent
              active={activeAccordions.includes(el.activeAccordion)}
            >
              <div className="qualification-question-subheader">
                {el.subheader}
              </div>
              <QuestionForm
                qualificationQuestions={qualificationQuestions}
                isKnockout={el.type === 'knockout'}
                updateProject={updateProject}
              />
              <div className="add-more">
                <Button
                  size="small"
                  disabled={
                  (el.type === 'knockout' ? knockoutQuestions : supplementaryQuestions)
                    .slice(-1)[0]?.question.length === 0
            || qualificationQuestions.length >= QUALIFICATION_QUESTIONS_MAX_LENGTH
          }
                  className="basic-btn"
                  onClick={() => updateProject({
                    qualificationQuestions: qualificationQuestions.concat(el.newQuestion),
                  })}
                >
                  Add more
                </Button>
              </div>
            </AccordionContent>
          </Accordion>
        ))}
      </Form.Field>

    </div>
  );
};

export default QualificationQuestions;
