import { gql } from '@apollo/client';

const TRANSACTIONS = gql`
  query Transactions($teamId: ID!, $pagination: Pagination) {
    transactions(teamId: $teamId, pagination: $pagination) {
      total
      transactions {
        id
        type
        amount
        credits
        description
        createdAt
        team {
          name
        }
        job {
          expert {
            fullName
          }
          project {
            title
          }
        }
      }
    }
  }
`;

export const TRANSACTIONS_BY_DATE = gql`
  query TransactionsByDate($teamId: ID, $startDate: Date!, $endDate: Date!) {
    transactionsByDate(teamId: $teamId, startDate: $startDate, endDate: $endDate) {
      id
      type
      credits
      description
      createdAt
      job {
        id
        expert {
          fullName
        }
        project {
          id
          title
          type
          code
        }
      }
    }
  }
`;

export const PAY_EXPERT = gql`
  mutation PayExpert($jobId: ID!, $payment: PaymentInput!) {
    payExpert(jobId: $jobId, payment: $payment) {
      id
      bonuses {
        id
        description
        amount
      }
    }
  }
`;

export default TRANSACTIONS;
