import React, { useContext } from 'react';
import { Button } from 'semantic-ui-react';
import InviteExpertModal from '../InviteModal/InviteExpertModal';
import DataContext from '../../contexts/DataContext';

const RequestButton = ({ expert }) => {
  const { managedProjects } = useContext(DataContext);

  const numJobsInvited = managedProjects.filter(
    (p) => p.status !== 'Completed' && p.jobs.some((j) => j.user.id === expert.id),
  );

  return (
    <InviteExpertModal expert={expert}>
      <Button
        fluid
        className={numJobsInvited.length > 0 ? 'primary-btn' : 'basic-btn'}
      >
        {numJobsInvited.length > 0 ? `Invited to ${numJobsInvited.length} project(s)` : 'Invite to project'}
      </Button>
    </InviteExpertModal>
  );
};

export default RequestButton;
