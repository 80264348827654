import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Tab, Header, Loader } from 'semantic-ui-react';
import ViewProject from '../components/Projects/ViewProject';
import ManageProjectJobs from '../components/Projects/ManageProjectJobs';
import TrackProjectProgress from '../components/Projects/TrackProjectProgres';
import DataContext from '../contexts/DataContext';
import PROJECT from '../queries/project';

const ProjectDetail = () => {
  const { id } = useParams();
  const { user } = useContext(DataContext);

  const { data, loading } = useQuery(PROJECT, {
    variables: { id },
  });

  if (loading) return <Loader active />;

  const panes = [
    { menuItem: 'View project', render: () => <ViewProject project={data.project} /> },
    { menuItem: 'Manage experts', render: () => <ManageProjectJobs project={data.project} user={user} /> },
    { menuItem: 'Track progress', render: () => <TrackProjectProgress project={data.project} /> },
  ];

  return (
    <div id="project-detail">
      <Header>{data.project.title}</Header>
      <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
    </div>
  );
};

export default ProjectDetail;
