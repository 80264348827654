import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  Segment, Header, Divider, List, FormField,
  Button, Modal, Loader, Grid, Checkbox, Radio, Form,
  Container,
} from 'semantic-ui-react';
import { UPDATE_PROJECT } from '../../../queries/project';
import ProjectForm from '../ProjectForm';
import SearchLabel from '../../SearchLabel';
import Knockout from '../ProjectForm/QualificationQuestions/Knockout';
import QualtricsSurveyAccess from '../QualtricsSurveyAccess';

const Details = ({ project }) => {
  const [editing, setEditing] = useState(false);
  const [updateProject, { loading }] = useMutation(UPDATE_PROJECT, {
    onCompleted: () => setEditing(false),
  });
  const submitEdits = (projectInput) => updateProject(
    { variables: { id: project.id, input: projectInput } },
  );

  const sortedQualificationQuestions = project.qualificationQuestions.slice().sort((a, b) => {
    if (a.questionType === 'knockout' && b.questionType !== 'knockout') {
      return -1;
    } if (a.questionType !== 'knockout' && b.questionType === 'knockout') {
      return 1;
    }
    return 0;
  });
  return (
    <Segment className="project-form">
      <strong className="project-title">Project Details</strong>
      <Divider />
      {
        // TODO: Remove the project.qualtricsSurvey check after all old surveys are done
        project.type === 'Survey' && project.qualtricsSurvey
        && <QualtricsSurveyAccess qualtricsSurvey={project.qualtricsSurvey} showWarning />
      }
      <Header>Project type</Header>
      <p>{project.type}</p>
      <Header>Project title</Header>
      <p>{project.title}</p>
      <Header>Project description</Header>
      <div className="text-description">{project.description}</div>
      {
        project.keywords.length !== 0 && (
          <>
            <Header>Project keywords</Header>
            <List horizontal>
              {
                project.keywords.map((tag) => (
                  <List.Item key={tag}>
                    <SearchLabel query={tag} />
                  </List.Item>
                ))
              }
            </List>
            <p style={{ marginTop: '1rem' }}>
              <SearchLabel
                query={project.keywords.join(' ')}
                textDisplay="Search experts using all project keywords"
              />
            </p>
          </>
        )
      }
      {project.type === 'Survey' && (
        <>
          <Header>Target N</Header>
          <p>{project.targetN}</p>
          {/* TODO: Remove after all old client surveys are done */}
          {project.surveyLink && (
            <>
              <Header>Survey Link</Header>
              <p>{project.surveyLink}</p>
            </>
          )}
        </>
      )}
      {project.qualificationQuestions?.length > 0 && (
        <>
          <Header>
            Qualification Questions
          </Header>
          <Container className="view-qualification-questions">
            <List bulleted>
              {sortedQualificationQuestions.map((q) => (
                <List.Item key={q.id}>
                  {q.question}
                  <div className="view-answer-choices">
                    { q.questionType === 'knockout' && <Knockout />}
                    { q.questionType === 'shortAnswer' && <Form.Input className="short-answer" fluid size="small" placeholder="Short answer" readOnly />}
                    {(q.questionType === 'multipleChoice' || q.questionType === 'checkbox')
                    && [...q.answerChoices, q.otherIncluded ? 'Other________' : null].map((el, index) => el && (
                    <Form size="mini" key={el.concat(index)}>
                      <FormField
                        control={q.questionType === 'checkbox' ? Checkbox : Radio}
                        readOnly
                        label={el}
                      />
                    </Form>
                    ))}
                  </div>
                </List.Item>
              ))}
            </List>
          </Container>
        </>
      )}
      {project.status !== 'Completed' && (
        <Modal
          closeIcon
          size="large"
          open={editing}
          onOpen={() => setEditing(true)}
          onClose={() => setEditing(false)}
          trigger={<Button className="basic-btn" size="tiny" content="Edit" />}
        >
          <Modal.Header>Edit Project Details</Modal.Header>
          <Modal.Content>
            <Grid id="edit-project">
              <Grid.Column>
                <Loader active={loading} content="loading" />
                <ProjectForm
                  project={project}
                  submit={submitEdits}
                  onCancel={() => setEditing(false)}
                />
              </Grid.Column>
            </Grid>
          </Modal.Content>
        </Modal>
      )}
    </Segment>
  );
};

export default Details;
