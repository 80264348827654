import React, { useState } from 'react';
import {
  Form, Grid, Segment, Button, Header, Message,
} from 'semantic-ui-react';
import Type from './Type';
import Details from './Details';
import QualificationQuestions from './QualificationQuestions';
import Keywords from './Keywords';

const FORM_STEPS = ['details', 'qualification questions', 'keywords'];

const ProjectForm = ({
  project: defaultProject, loading, submit, onCancel,
}) => {
  const [project, setProject] = useState(defaultProject);
  const [currentStep, setCurrentStep] = useState(project.id ? 'keywords' : 'details');
  const [error, setError] = useState(null);

  const updateProject = (data) => {
    const key = Object.keys(data)[0];
    setError({ ...error, [key]: null });
    setProject({ ...project, ...data });
  };

  const validateForm = () => {
    const {
      type, title, description, targetN, qualificationQuestions, keywords,
    } = project;

    if (!title || !description) return false;
    if (type === 'Survey' && targetN < 1) return false;

    if (FORM_STEPS.indexOf(currentStep) >= FORM_STEPS.indexOf('qualification questions')) {
      if (
        qualificationQuestions.some(
          (q) => (q.question.trim().length > 0 && q.question.trim().length <= 5),
        )
      ) {
        setError({
          qualificationQuestions: 'Each qualification question should have a length of at least 6 characters',
        });
        return false;
      }
      if (qualificationQuestions.some((q) => (q.question.trim().length > 0)
      && (q.questionType === 'multipleChoice' || q.questionType === 'checkbox')
          && q.answerChoices.every((choice) => choice.trim().length === 0))) {
        setError({
          qualificationQuestions: 'At least one answer choice must be provided for multiple choice or checkbox questions',
        });
        return false;
      }
    }
    if (FORM_STEPS.indexOf(currentStep) >= FORM_STEPS.indexOf('keywords')) {
      if (keywords.length === 0) {
        setError({ keywords: 'Please enter one or more project keywords' });
        return false;
      }
    }
    return true;
  };

  const validateAndNext = () => {
    if (validateForm()) {
      setCurrentStep(FORM_STEPS[FORM_STEPS.indexOf(currentStep) + 1]);
    }
  };

  const validateAndSubmit = () => {
    const {
      type, title, description, keywords, targetN, qualificationQuestions,
    } = project;

    const sanitizedQualificationQuestions = qualificationQuestions.map((q) => {
      const {
        questionType, answerChoices, otherIncluded, question,
      } = q;

      const validAnswerChoices = answerChoices?.map((choice) => choice.trim())
        .filter((choice) => choice.length > 0);

      const questionWithChoices = questionType === 'checkbox' || questionType === 'multipleChoice';
      return {
        questionType,
        ...(questionWithChoices && { answerChoices: validAnswerChoices, otherIncluded }),
        question: question.trim(),
      };
    }).filter((q) => q.question.length > 0);

    if (validateForm()) {
      submit({
        type,
        title,
        description,
        keywords,
        targetN: type === 'Interview' ? null : targetN,
        qualificationQuestions: sanitizedQualificationQuestions,
      });
    }
  };

  return (
    <Form error={error !== null}>
      <Grid.Row>
        {!project.id && (
          <Segment className="project-form content">
            <Type project={project} updateProject={updateProject} />
          </Segment>
        )}
        {project.type && (
          <div>
            <Segment className="project-form content">
              <Details project={project} updateProject={updateProject} />
              {currentStep === 'details' && (
              <Form.Field className="next">
                <Button
                  content="Next"
                  className="primary-btn"
                  onClick={validateAndNext}
                />
              </Form.Field>
              )}
            </Segment>
            <Segment
              className="project-form content"
              disabled={FORM_STEPS.indexOf(currentStep) < FORM_STEPS.indexOf('qualification questions')}
            >
              {error?.qualificationQuestions && (
              <Message
                error
                content={error.qualificationQuestions}
                onDismiss={() => setError({ ...error, qualificationQuestions: null })}
              />
              )}
              {FORM_STEPS.indexOf(currentStep) < FORM_STEPS.indexOf('qualification questions') ? (
                <Header>Qualification Questions (Optional)</Header>
              ) : (
                <>
                  <QualificationQuestions project={project} updateProject={updateProject} />
                  {currentStep === 'qualification questions' && (
                  <Form.Field className="next">
                    <Button
                      content="Next"
                      className="primary-btn"
                      onClick={validateAndNext}
                    />
                  </Form.Field>
                  )}
                </>
              )}
            </Segment>
            <Segment
              className="project-form content"
              disabled={FORM_STEPS.indexOf(currentStep) < FORM_STEPS.indexOf('keywords')}
            >
              {error?.keywords && (
              <Message
                error
                content={error.keywords}
                onDismiss={() => setError({ ...error, keywords: null })}
              />
              )}
              {FORM_STEPS.indexOf(currentStep) < FORM_STEPS.indexOf('keywords') ? (
                <Header>Project Keywords</Header>
              ) : (
                <Keywords
                  project={project}
                  updateProject={updateProject}
                />
              )}
            </Segment>
          </div>
        )}
      </Grid.Row>
      <Grid.Row className="actions">
        <Form.Field className="buttons">
          <Button as="a" loading={loading} disabled={loading} className="basic-btn" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            className="primary-btn"
            loading={loading}
            disabled={currentStep !== 'keywords' || loading}
            onClick={validateAndSubmit}
          >
            {project.id ? 'Save' : 'Create Project'}
          </Button>
        </Form.Field>
      </Grid.Row>
    </Form>
  );
};

export default ProjectForm;
