const SETTING_HEADINGS = {
  profile: {
    title: 'User Profile',
    description: 'Add your first and last name',
  },
  availability: {
    title: 'My Availability',
    description: `Experts you invite will schedule calls based on your availability. Use the tabs below to let them know your open times.
    `,
  },
  members: {
    title: 'Members',
    description: '(Coming soon) Create new users, customize user roles, and remove users from your account.',
  },
  credits: {
    title: 'Credits',
    description: 'Keep track of your team\'s credit history',
  },
};

export default SETTING_HEADINGS;
