import React, { useState, useContext } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Modal, Form, Divider } from 'semantic-ui-react';
import { INVITE_EXPERT_TO_PROJECT } from '../../queries/job';
import ProjectNewButton from './ProjectNewButton';
import SendInviteButton from './SendInviteButton';
import InsufficientCreditMessage from './InsufficientCreditMessage';
import EXPERT_PROFILE from '../../queries/expert';
import DataContext from '../../contexts/DataContext';
import MixpanelTracker from '../../utils/MixpanelTracker';
import MessageContext from '../../contexts/MessageContext';
import SearchContext from '../../contexts/SearchContext';

const InviteExpertModal = ({ expert, children }) => {
  const { user, managedProjects, team } = useContext(DataContext);
  const { setSuccessPopup } = useContext(MessageContext);
  const searchContext = useContext(SearchContext);

  const [open, setOpen] = useState(false);
  const [projectIds, setProjectIds] = useState([]);
  const [error, setError] = useState(false);

  // TODO: Try to remove this as an analytics track should never need to burden the backend
  // Fetch expert profile query because some additional profile data is required by Mixpanel
  const { data } = useQuery(EXPERT_PROFILE, {
    skip: !open,
    variables: { id: expert.id },
  });

  const [inviteExpert, { loading }] = useMutation(INVITE_EXPERT_TO_PROJECT);

  const onCheckboxClick = (checked, project) => {
    let newProjectIds = projectIds;
    if (checked) {
      MixpanelTracker.trackSelectProject(expert, { id: project.id, title: project.title });
      newProjectIds = projectIds.concat(project.id);
    } else {
      newProjectIds = projectIds.filter((pId) => pId !== project.id);
    }
    setProjectIds(newProjectIds);
    setError(team.availableInvites < newProjectIds.length);
  };

  const onRequest = () => {
    const promises = [];
    projectIds.forEach((projectId) => {
      if (searchContext.searchResult) {
        const project = managedProjects.filter((p) => p.id === projectId)[0];
        // Use expert profile query to track because some profile data is required by Mixpanel
        MixpanelTracker.trackSendInvite(user, team, data.expert, project, searchContext);
      }
      promises.push(inviteExpert({ variables: { userId: expert.id, projectId } }));
    });
    Promise.all(promises).then(() => {
      setSuccessPopup({
        title: `Your invite has been sent to ${expert.fullName}`,
        content: `
          Great news! Your invite is on its way.
          Please check your Dexter dashboard for status updates.
        `,
        onClose: () => {
          setProjectIds([]);
          setOpen(false);
        },
      });
    });
  };

  return (
    <Modal
      closeIcon
      open={open}
      onOpen={() => {
        setOpen(true);
        MixpanelTracker.trackOpenInviteModal(expert);
      }}
      onClose={() => setOpen(false)}
      trigger={children}
      size="small"
      id="request-modal"
    >
      <Modal.Header>
        {`Invite ${expert.fullName} to...`}
        {projectIds.length > 0 && <span className="meta">{`${projectIds.length} project(s) selected`}</span>}
      </Modal.Header>
      <Modal.Content>
        {error && <InsufficientCreditMessage />}
        <Form>
          {managedProjects.filter((p) => p.displayForInviteModal).map((p) => {
            const isInvited = p.jobs.some((j) => j.user.id === expert.id);
            return (
              <Form.Checkbox
                key={p.id}
                label={`${p.isUnpublishedSurvey ? '(Unpublished survey) ' : ''}${p.title}`}
                defaultChecked={isInvited || projectIds.includes(p.id)}
                disabled={isInvited || p.isUnpublishedSurvey}
                onClick={(e, { checked }) => onCheckboxClick(checked, p)}
              />
            );
          })}
          <ProjectNewButton />
        </Form>
        <Divider />
        <span className="meta">{`You have ${team.availableInvites} invites left!`}</span>
      </Modal.Content>
      <Modal.Actions>
        <SendInviteButton
          loading={loading}
          disabled={team.availableInvites < projectIds.length || projectIds.length === 0 || loading}
          onClick={onRequest}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default InviteExpertModal;
