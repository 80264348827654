import React, { useState, useContext } from 'react';
import { useMutation } from '@apollo/client';
import {
  Icon, Grid, Popup, Form, Button, Divider,
} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import PROFILE, { UPDATE_USER } from '../../../queries/profile';
import MessageContext from '../../../contexts/MessageContext';
import 'react-datepicker/dist/react-datepicker.css';

const WeeklyHours = ({ userAvailability }) => {
  const { setErrorMsg } = useContext(MessageContext);
  const [availability, setAvailability] = useState(userAvailability);

  const [updateUser] = useMutation(UPDATE_USER, {
    update(cache, { data }) {
      cache.writeQuery({
        query: PROFILE,
        data: { me: data.updateUser },
      });
    },
    onError: (error) => {
      setErrorMsg(`${error.message}. Please modify the time.`);
    },
  });

  const onSave = (newAvailability) => {
    setAvailability(newAvailability);
    setErrorMsg('');
    updateUser({ variables: { input: { availability: newAvailability } } });
  };

  const timeStringToNumber = (timeString) => {
    const numberString = timeString.split(':').join('');
    return parseInt(numberString, 10);
  };

  const numberToTimeString = (number) => {
    if (number < 0) return null;
    let timeString = number.toString();
    timeString = '0'.repeat(4 - timeString.length) + timeString;
    return `${timeString.substring(0, 2)}:${timeString.substring(2, 4)}`;
  };

  const onTimeChange = (dateString, type, index) => {
    const date = new Date(dateString);
    const hour = (date.getHours() < 10 ? '0' : '') + date.getHours();
    const min = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
    const time = timeStringToNumber(`${hour}:${min}`);
    const tempAvailability = availability.slice();
    const { dayOfWeek, startTime, endTime } = tempAvailability[index];
    const newTimeslot = {
      dayOfWeek,
      startTime: type === 'startTime' ? time : startTime,
      endTime: type === 'endTime' ? time : endTime,
    };
    tempAvailability.splice(index, 1);
    onSave(tempAvailability.concat(newTimeslot));
  };

  const setDateTime = (number) => {
    const timeString = numberToTimeString(number);
    const d = moment().format('L');
    return new Date(`${d} ${timeString}`);
  };

  const WEEKDAYS = [
    'SUN', 'MON', 'TUE', 'WED', 'THUR', 'FRI', 'SAT',
  ];

  const renderDay = (day) => (
    <Grid.Column width={2} verticalAlign="middle">
      {day}
    </Grid.Column>
  );

  const renderPlus = (dayIndex) => (
    <Grid.Column width={2} verticalAlign="middle">
      <Popup
        content="Add a time interval"
        trigger={(
          <Button
            icon
            basic
            onClick={() => {
              onSave(availability.concat({
                dayOfWeek: dayIndex,
                startTime: 900,
                endTime: 1700,
              }));
            }}
          >
            <Icon name="plus" />
          </Button>
        )}
      />
    </Grid.Column>
  );

  return (
    <Grid centered stackable className="hours">
      {WEEKDAYS.map((day, index) => {
        if (availability.some((a) => a.dayOfWeek === index)) {
          return (
            <React.Fragment key={day}>
              <Grid.Row>
                {renderDay(day)}
                <Grid.Column width={12} verticalAlign="middle">
                  <Grid stackable>
                    {availability.map((a, aIndex) => {
                      const keyIndex = `${a.dayOfWeek}_${aIndex}`;
                      if (a.dayOfWeek === index) {
                        return (
                          <Grid.Row key={keyIndex}>
                            <Grid.Column width={6}>
                              <Form>
                                <DatePicker
                                  selected={setDateTime(a.startTime)}
                                  onChange={(date) => onTimeChange(date, 'startTime', aIndex)}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={30}
                                  dateFormat="h:mm aa"
                                />
                              </Form>
                            </Grid.Column>
                            <Grid.Column textAlign="center" verticalAlign="middle">
                              to
                            </Grid.Column>
                            <Grid.Column width={6}>
                              <Form>
                                <DatePicker
                                  selected={setDateTime(a.endTime)}
                                  onChange={(date) => onTimeChange(date, 'endTime', aIndex)}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={30}
                                  dateFormat="h:mm aa"
                                />
                              </Form>
                            </Grid.Column>
                            <Grid.Column width={1}>
                              <Button
                                icon
                                basic
                                onClick={() => {
                                  const tempAvailability = availability.slice();
                                  tempAvailability.splice(aIndex, 1);
                                  onSave(tempAvailability);
                                }}
                              >
                                <Icon name="trash alternate outline" />
                              </Button>
                            </Grid.Column>
                          </Grid.Row>
                        );
                      }
                      return null;
                    })}
                  </Grid>
                </Grid.Column>
                {renderPlus(index)}
              </Grid.Row>
              <Divider />
            </React.Fragment>
          );
        }
        return (
          <React.Fragment key={day}>
            <Grid.Row>
              {renderDay(day)}
              <Grid.Column width={12} verticalAlign="middle">
                Unavailable
              </Grid.Column>
              {renderPlus(index)}
            </Grid.Row>
            <Divider />
          </React.Fragment>
        );
      })}
    </Grid>
  );
};

export default WeeklyHours;
