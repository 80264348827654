import React, { useState, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { Modal, Header, Button } from 'semantic-ui-react';
import MessageContext from '../../contexts/MessageContext';
import { MANAGED_PROJECTS, UPCOMING_MEETINGS } from '../../queries/profile';
import { PROJECT_JOBS } from '../../queries/project';
import { UPDATE_JOB } from '../../queries/job';

const ReportNoShow = ({ job, children }) => {
  const [open, setOpen] = useState(false);
  const { setSuccessPopup } = useContext(MessageContext);

  const [pendingNoShow, { loading }] = useMutation(UPDATE_JOB, {
    refetchQueries: [
      { query: UPCOMING_MEETINGS },
      { query: MANAGED_PROJECTS },
      { query: PROJECT_JOBS, variables: { id: job.project.id } },
    ],
    onCompleted: () => {
      setSuccessPopup({
        title: 'Your report has been submitted',
        content: `No further action is needed from you at the moment.
          Our sincere apologies for the trouble.
          Please don't hesitate to reach out to us at help@dexterexperts.com if you
          have any questions/concerns.
        `,
        onClose: () => setOpen(false),
      });
    },
  });

  return (
    <Modal
      closeIcon
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      trigger={children}
      className="setting-modal-layout"
    >
      <Modal.Header>Report a No Show</Modal.Header>
      <Modal.Content>
        <Header>
          {`Are you sure you want to mark your meeting with
            ${job.user.fullName} as a no show ?`}
        </Header>
        <p>
          Please stay on the conference line for at least 10 minutes as a courtesy.
          Once submitted, this meeting will be canceled.
        </p>
      </Modal.Content>
      <Modal.Actions>
        <span className="subtext">
          This action cannot be undone
        </span>
        <Button className="basic-btn" onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button
          loading={loading}
          disabled={loading}
          className="primary-btn"
          onClick={() => pendingNoShow({
            variables: {
              userId: job.user.id,
              projectId: job.project.id,
              job: { overall_status: 'No Show' },
            },
          })}
        >
          Submit
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ReportNoShow;
