import React from 'react';
import { useQuery } from '@apollo/client';
import Loading from '../components/Loading';
import PROFILE, { MANAGED_PROJECTS } from '../queries/profile';
import TEAM_PROFILE from '../queries/team';
import { MY_LISTS } from '../queries/list';

const DataContext = React.createContext({
  user: null, team: null, managedProjects: [], myLists: null,
});

export const DataProvider = ({ children }) => {
  const { loading: profileLoading, data: profileData, refetch: profileRefetch } = useQuery(PROFILE);
  const {
    loading: managedProjectsLoading,
    data: managedProjectsData,
    refetch: managedProjectsRefetch,
  } = useQuery(MANAGED_PROJECTS);
  const teamId = profileData?.me?.membership[0]?.teamId;
  const { loading: teamLoading, data: teamData, refetch: teamRefetch } = useQuery(TEAM_PROFILE, {
    skip: !teamId,
    variables: { id: teamId },
  });
  const { loading: myListsLoading, data: myListsData } = useQuery(MY_LISTS);

  if (profileLoading || managedProjectsLoading || teamLoading || myListsLoading) return <Loading />;

  return (
    <DataContext.Provider
      value={{
        user: profileData.me,
        profileRefetch,
        managedProjects: managedProjectsData.me.managedProjects.map((p) => ({
          ...p,
          isUnpublishedSurvey: p.qualtricsSurvey && p.qualtricsSurvey.status === 'New',
          displayForInviteModal: p.status !== 'Completed',
        })),
        managedProjectsRefetch,
        team: teamData?.team,
        teamRefetch,
        myLists: myListsData.myLists,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;
