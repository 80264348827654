import React, { useState } from 'react';
import {
  Modal, Button, List, Header,
} from 'semantic-ui-react';
import ReviewModal from '../Meetings/ReviewModal';
import CustomLink from '../CustomLink';

const QualificationResponses = ({ children, job }) => {
  const [open, setOpen] = useState(false);
  return (
    <Modal
      closeIcon
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      trigger={children}
      id="qualification-responses"
    >
      <Modal.Header>{job.user.fullName}</Modal.Header>
      <Modal.Content>
        {!job.expert_rating && job.overall_status === 'Completed' && (
          <p>
            Did you find the response to be accurate?
            <ReviewModal meeting={job}>
              <CustomLink>
                Leave a review
              </CustomLink>
            </ReviewModal>
          </p>
        )}
        <List bulleted>
          {job.qualificationResponses.map((r) => (
            <List.Item key={`${r.question}${new Date().getTime()}`}>
              <Header as="h4">
                {r.question}
              </Header>
              {r.response.map((el) => (
                <List bulleted key={`${el}${new Date().getTime()}`}>
                  <List.Item>
                    {el}
                  </List.Item>
                </List>
              ))}
            </List.Item>
          ))}
        </List>
      </Modal.Content>
      <Modal.Actions>
        <Button className="primary-btn" onClick={() => setOpen(false)}>Close</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default QualificationResponses;
