import React from 'react';
import { Header, Dropdown } from 'semantic-ui-react';

const BUFFER_TIMES = [0, 30, 60];

const BufferTime = ({ settings, setSettings }) => (
  <div className="additional-setting">
    <Header>
      Buffer time
      <Header.Subheader>
        Add time between booked events
      </Header.Subheader>
    </Header>
    <div>
      {'Between events: '}
      <Dropdown
        selection
        value={settings.bufferMinutes}
        options={BUFFER_TIMES.map((time) => (
          { key: time, text: time, value: time }
        ))}
        onChange={(e, { value }) => setSettings({ ...settings, bufferMinutes: value })}
      />
      {' minutes'}
    </div>
  </div>
);

export default BufferTime;
