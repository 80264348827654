import React from 'react';
import { Table, Radio } from 'semantic-ui-react';
import BarChart from './BarChart';
import FILTER_CATEGORIES from '../../../constants/filters';
import ProjectForecastHelper from '../../../utils/ProjectForecastHelper';

const DEFAULT_BAR_WIDTH_FACTOR = 0.8;

const FilterByCategory = ({
  timezone, daysFromNow, jobs, categoryFilters, updateFilters, resetFilters,
}) => {
  // Get forecast responses data from all jobs
  const {
    numResponses: totalNumResponses,
  } = ProjectForecastHelper.getResponseForecast(jobs, daysFromNow, timezone);

  const renderFilterValues = (category) => {
    const isFiltered = !!categoryFilters[category];
    const filtersToApply = { ...categoryFilters };
    // Do not apply the filter within the same category
    if (isFiltered) delete filtersToApply[category];

    // Get filter value with response forecast before applying filter
    const responseForecasts = ProjectForecastHelper
      .getFilterValuesWithResponseForecast(jobs, daysFromNow, timezone, {}, category)
      // Hide the 0s initially
      .filter((f) => f.numResponses !== 0);

    // Get filter value with response forecast after applying filter
    const filteredResponseForecasts = ProjectForecastHelper.getFilterValuesWithResponseForecast(
      jobs, daysFromNow, timezone, filtersToApply, category,
    );

    // Render all filter values without applied filter
    // Render the correct stats with applied filter
    // The purpose is to get rid of the filter values that are not part of the forecast
    return responseForecasts.map(({ name }) => {
      const {
        numCompleted,
        numScheduled,
        numPredicted,
        numResponses,
      } = filteredResponseForecasts.find((f) => f.name === name);

      const forecastPercentage = Math.round((numResponses / totalNumResponses) * 100);
      const barWidthPercentage = forecastPercentage * DEFAULT_BAR_WIDTH_FACTOR;
      const checked = (isFiltered && categoryFilters[category] === name) || false;
      const disabled = isFiltered && !checked;
      return (
        <Table.Row key={name} className="facet">
          <Table.Cell width={1}>
            <Radio
              label={name}
              checked={checked}
              onChange={() => updateFilters(name, category)}
            />
          </Table.Cell>
          <Table.Cell disabled={disabled}>
            <BarChart
              data={{ numCompleted, numScheduled, numPredicted }}
              width={`${barWidthPercentage}%`}
              displayBarOnly
              disabled={disabled}
            />
            <div className="stats">
              {`${numResponses} (${forecastPercentage}%)`}
            </div>
          </Table.Cell>
        </Table.Row>
      );
    });
  };
  return (
    <Table basic="very" compact stackable className="filter-by-category">
      <Table.Header fullWidth>
        <Table.Row>
          {Object.keys(categoryFilters).length !== 0 && (
            <Table.Cell className="reset-filters" onClick={resetFilters}>Reset Filters</Table.Cell>
          )}
        </Table.Row>
      </Table.Header>
      {Object.keys(FILTER_CATEGORIES).map((c) => (
        <Table.Body key={c} className="filter-table">
          <Table.Row>
            <Table.Cell colSpan={3} className="category">
              <div>
                {`By ${FILTER_CATEGORIES[c].title}`}
                {FILTER_CATEGORIES[c].isArray && (
                <div className="sub-title">
                  Some experts have more than one tag, and the sum may exceed 100%
                </div>
                )}
              </div>
            </Table.Cell>
          </Table.Row>
          {renderFilterValues(c)}
          {/* For extra spacing between each category group */}
          <Table.Row><Table.Cell /></Table.Row>
          <Table.Row><Table.Cell /></Table.Row>
        </Table.Body>
      ))}
    </Table>
  );
};

export default FilterByCategory;
