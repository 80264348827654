import React, { useState, useContext } from 'react';
import { useMutation } from '@apollo/client';
import {
  Form, Button, DropdownItem,
} from 'semantic-ui-react';
import { CREATE_LIST, ADD_EXPERT_TO_LIST, MY_LISTS } from '../../queries/list';
import CustomLink from '../CustomLink';
import MessageContext from '../../contexts/MessageContext';

const ListNew = ({ expert, setLoading }) => {
  const { setErrorMsg } = useContext(MessageContext);
  const [expanded, setExpanded] = useState(false);
  const [name, setName] = useState('');
  const [createList, { error }] = useMutation(CREATE_LIST, {
    refetchQueries: [{ query: MY_LISTS }],
    onCompleted: () => {
      setExpanded(false);
      setName('');
      setErrorMsg('');
    },
    onError: () => {
      setLoading(false);
      setErrorMsg(error.message);
    },
  });
  const [addExpertToList] = useMutation(ADD_EXPERT_TO_LIST);

  const createListAndAddExpert = () => {
    setLoading(true);
    createList({ variables: { input: { name } } }).then((result) => {
      const listId = result.data.createList.id;
      addExpertToList({ variables: { listId, expertId: expert.id } });
      setLoading(false);
    });
  };

  return (
    <DropdownItem>
      {!expanded && (
        <CustomLink
          onClick={(e) => {
            e.stopPropagation();
            setExpanded(true);
          }}
        >
          Save to new list
        </CustomLink>
      )}
      {expanded && (
        <Form
          onSubmit={() => createListAndAddExpert()}
        >
          <Form.Input
            required
            placeholder="New list name..."
            size="small"
            value={name}
            onClick={(e) => e.stopPropagation()}
            onKeyDown={(e) => e.stopPropagation()}
            onChange={(e, { value }) => {
              setName(value);
            }}
          />
          <Button
            className="primary-btn"
            size="tiny"
            disabled={name.trim().length === 0}
          >
            Save
          </Button>
        </Form>
      )}
    </DropdownItem>
  );
};

export default ListNew;
