const FILTER_CATEGORIES = {
  country: {
    title: 'Country',
  },
  title: {
    title: 'Job Title',
  },
  'organization.name': {
    title: 'Organization',
  },
  'organization.type': {
    title: 'Organization Type',
  },
  technology_tags: {
    title: 'Technology Tags',
    isArray: true,
  },
  medical_specialties: {
    title: 'Medical Specialties',
    isArray: true,
  },
};
export default FILTER_CATEGORIES;
