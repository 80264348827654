import React from 'react';
import { Button } from 'semantic-ui-react';

const TourLinks = ({
  nextButton, prevButton, onPrev, onNext, primary,
}) => (
  <div>
    <Button className="basic-btn" onClick={onPrev}>{prevButton}</Button>
    <Button
      floated="right"
      className={primary ? 'primary-btn' : 'basic-btn'}
      onClick={onNext}
    >
      {nextButton}
    </Button>
  </div>
);

export default TourLinks;
