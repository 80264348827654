import React, { useState, useContext } from 'react';
import { useQuery } from '@apollo/client';
import {
  Card, Segment, Header, Grid, Pagination,
} from 'semantic-ui-react';
import { PROJECTS } from '../queries/project';
import Loading from '../components/Loading';
import CreateProjectButton from '../components/Projects/CreateProjectButton';
import ProjectFilters from '../components/Projects/ProjectFilters';
import ProjectCard from '../components/Projects/ProjectCard';
import DataContext from '../contexts/DataContext';

const PROJECTS_PER_PAGE = 10;
const DEFAULT_FILTERS = {
  query: '',
  status: ['Active', 'Draft'],
  service_type: 'Self-Service',
  myProjectsOnly: true,
};

const Projects = () => {
  const { user, team } = useContext(DataContext);

  const [appliedFilters, setAppliedFilters] = useState({ ...DEFAULT_FILTERS, team: team.id });
  const [page, setPage] = useState(1);

  const { query, ...filterValues } = appliedFilters;
  const { data, loading } = useQuery(PROJECTS, {
    variables: {
      query,
      filters: filterValues,
      pagination: { page, per_page: PROJECTS_PER_PAGE },
    },
    // Prevent any cache that affects data updating
    fetchPolicy: 'network-only',
  });

  if (loading) return <Loading />;

  const renderProjects = () => (
    <div>
      {data.projects.total === 0 ? (
        <Grid container centered>
          <Segment>
            <div className="not-found">
              <Header as="h2">No Projects Found</Header>
              <Header>
                Set up a project and start inviting experts best fitted to answer your questions
              </Header>
              <CreateProjectButton />
            </div>
          </Segment>
        </Grid>
      ) : (
        <div className="project-list">
          <Card.Group itemsPerRow={2} stackable>
            {data.projects.projects.map(
              (project) => (
                <ProjectCard
                  key={project.id}
                  user={user}
                  project={project}
                />
              ),
            )}
          </Card.Group>
          <div className="pagination">
            <Pagination
              defaultActivePage={page}
              totalPages={Math.ceil(data.projects.total / PROJECTS_PER_PAGE)}
              onPageChange={(e, d) => setPage(d.activePage)}
            />
          </div>
        </div>
      )}
    </div>
  );

  return (
    <Grid id="projects" stackable>
      <Grid.Row>
        <Grid.Column textAlign="right">
          <CreateProjectButton />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={4}>
          <ProjectFilters
            filters={appliedFilters}
            updateFilter={(newData) => {
              setAppliedFilters({ ...appliedFilters, ...newData });
              setPage(1);
            }}
          />
        </Grid.Column>
        <Grid.Column width={12}>
          {renderProjects()}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default Projects;
