class ObjectHelper {
  static omitDeepArrayWalk(arr, key) {
    return arr.map((val) => {
      if (Array.isArray(val)) return this.omitDeepArrayWalk(val, key);
      if (typeof val === 'object') return this.omitDeep(val, key);
      return val;
    });
  }

  static omitDeep(obj, key) {
    const keys = Object.keys(obj);
    const newObj = {};
    keys.forEach((i) => {
      if (i !== key) {
        const val = obj[i];
        if (val instanceof Date) newObj[i] = val;
        else if (Array.isArray(val)) newObj[i] = this.omitDeepArrayWalk(val, key);
        else if (typeof val === 'object' && val !== null) {
          newObj[i] = this.omitDeep(val, key);
        } else newObj[i] = val;
      }
    });
    return newObj;
  }
}

export default ObjectHelper;
