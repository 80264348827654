import React from 'react';
import { Header, Form, List } from 'semantic-ui-react';

const PreInterviewMaterial = ({ settings, setSettings }) => (
  <div className="additional-setting">
    <Header>
      Pre-interview material
      <Header.Subheader>
        Provide a link if you have any interview material to
        share with the experts prior to the call
      </Header.Subheader>
    </Header>
    <strong>Important consideration</strong>
    <List bulleted>
      <List.Item>
        Pre-interview materials should not include sensitive
        or confidential information, and they should be concise
        and scannable, allowing for a maximum review time of 5 minutes
      </List.Item>
      <List.Item>
        Experts should not be expected to complete or return any materials.
        In such cases, reach out to Dexter staff for further support
      </List.Item>
    </List>
    <Form.Input
      fluid
      placeholder="https://www.mylink.com"
      value={settings.preInterviewLink}
      onChange={(e, { value }) => setSettings({ ...settings, preInterviewLink: value })}
    />
  </div>
);

export default PreInterviewMaterial;
