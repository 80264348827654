import React from 'react';
import { useHistory } from 'react-router-dom';
import { Header, Form, Radio } from 'semantic-ui-react';
import CustomLink from '../../../CustomLink';

const SCHEDULING_OPTIONS = {
  'All must attend': (
    <div className="text">
      {'Allow Experts to schedule only when '}
      <u>ALL project owners</u>
      {' are available.'}
    </div>
  ),
  'At least one': (
    <div className="text">
      {'Allow Experts to schedule when '}
      <u>AT LEAST 1 project owner</u>
      {' is available.'}
    </div>
  ),
};

const AttendanceRequirement = ({ settings, setSettings }) => {
  const history = useHistory();
  return (
    <div className="additional-setting">
      <Header>
        Interviewer attendance requirement
      </Header>
      <Form.Group grouped>
        {Object.keys(SCHEDULING_OPTIONS).map((option) => (
          <Form.Field key={option} className="scheduling-option">
            <Radio
              value={option}
              checked={settings.attendanceRequirement === option}
              onChange={(e, { value }) => setSettings({
                ...settings, attendanceRequirement: value,
              })}
            />
            <div className="label">{SCHEDULING_OPTIONS[option]}</div>
          </Form.Field>
        ))}
      </Form.Group>
      <Header as="h5">
        {`
            Note: Project owners' availability defaults to 9:00am - 5:00pm weekdays (in your local time).
            Make sure all owners update their availability
          `}
        <CustomLink onClick={() => history.replace('/settings/availability/available_time_blocks')}>
          here.
        </CustomLink>
      </Header>
    </div>
  );
};

export default AttendanceRequirement;
