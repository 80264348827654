import React from 'react';
import { Grid, Header } from 'semantic-ui-react';
import ProfilePicture from '../ProfilePicture';
import ProfileLabel from '../../ProfileLabel';
import RequestButton from '../../Meetings/RequestButton';
import ExpertListsButton from '../../Lists/ExpertListsButton';
import ExpertCardRating from '../ExpertCardRating';

const Heading = ({ expert }) => (
  <Grid container stackable>
    <Grid.Column width={8} className="left">
      <ProfilePicture expert={expert} />
      <Header as="h2">
        {expert.fullName}
        <Header.Subheader>
          {`${expert.title} ${expert.organization.name}`}
        </Header.Subheader>
        <Header.Subheader>{expert.country}</Header.Subheader>
        <ProfileLabel num={expert.numCompleted} type="call" />
        <ExpertCardRating expert={expert} />
      </Header>
    </Grid.Column>
    <Grid.Column width={8} className="right">
      <Grid stackable>
        <Grid.Row>
          <Grid.Column floated="right">
            <ProfileLabel num={expert.credits} type="credit" />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column className="right">
            <Grid>
              <Grid.Row className="button-row">
                <RequestButton expert={expert} />
              </Grid.Row>
              <Grid.Row className="button-row">
                <ExpertListsButton expert={expert} />
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Grid.Column>
  </Grid>
);

export default Heading;
