import React from 'react';
import { Button } from 'semantic-ui-react';
import InviteListModal from '../InviteModal/InviteListModal';

const InviteAllButton = ({ list }) => (
  <InviteListModal list={list}>
    <Button
      as="a"
      disabled={list.experts.length === 0}
      className="basic-btn"
      content="Invite all to project(s)"
    />
  </InviteListModal>
);
export default InviteAllButton;
