import { stateToHTML } from 'draft-js-export-html';
import { convertFromRaw } from 'draft-js';

class ConvertHelper {
  static richTextToHtml(richText) {
    if (!richText) {
      return '';
    }
    try {
      const result = JSON.parse(richText);
      return stateToHTML(convertFromRaw(result));
    } catch (err) {
      return `richTextToHtml Error: ${err.message}`;
    }
  }

  static richTextToPlainText(richText) {
    if (!richText) {
      return '';
    }
    try {
      const plainTexts = [];
      const parsedData = JSON.parse(richText);
      parsedData.blocks.map((obj) => plainTexts.push(obj.text));
      const plainText = plainTexts.join('\n');
      return plainText;
    } catch (err) {
      return `richTextToPlainText Error: ${err.message}`;
    }
  }

  static stripHtml(html) {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || '';
  }

  static countKeywordOccurences(string, keyword) {
    return string.toLowerCase().split(keyword.toLowerCase()).length - 1;
  }
}

export default ConvertHelper;
