import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';
import AuthContext from '../../contexts/AuthContext';
import ProfileLabel from '../ProfileLabel';
import SETTING_HEADINGS from '../../constants/settingHeadings';

const DropdownItem = ({ pathname }) => {
  const history = useHistory();
  const { title } = SETTING_HEADINGS[pathname];
  return (
    <Dropdown.Item
      as="a"
      onClick={() => history.push(`/settings/${pathname}`)}
    >
      {title}
    </Dropdown.Item>
  );
};

const UserMenu = ({ user, team }) => {
  const { logout } = useContext(AuthContext);

  return (
    <div className="nav-icon">
      <Dropdown
        className="settings"
        text={user.firstName ? user.firstName[0] : user.email[0]}
        button
      >
        <Dropdown.Menu>
          <Dropdown.Item disabled>{user.email}</Dropdown.Item>
          <DropdownItem pathname="profile" />
          <DropdownItem pathname="availability" />
          <DropdownItem pathname="members" />
          <DropdownItem pathname="credits" />
          <Dropdown.Item
            as="a"
            onClick={logout}
          >
            Logout
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <ProfileLabel num={team.availableCredits} type="credit" />
    </div>
  );
};

export default UserMenu;
