import React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Button, Dropdown } from 'semantic-ui-react';
import { FOLLOWED_LISTS, FOLLOW_LIST, UNFOLLOW_LIST } from '../../queries/list';

const FollowListButton = ({ list }) => {
  const { loading, data } = useQuery(FOLLOWED_LISTS);
  const [followList, { loading: following }] = useMutation(FOLLOW_LIST, {
    refetchQueries: [{ query: FOLLOWED_LISTS }],
  });
  const [unfollowList, { loading: unfollowing }] = useMutation(UNFOLLOW_LIST, {
    refetchQueries: [{ query: FOLLOWED_LISTS }],
  });

  if (loading || following || unfollowing) return <Button loading>Loading</Button>;

  return (
    <>
      {data.followedLists.some((l) => l.id === list.id) && (
        <Dropdown
          text="Following"
          button
          className="primary-btn"
          options={[{ key: 1, value: 1, text: 'Unfollow' }]}
          onChange={() => unfollowList({ variables: { listId: list.id } })}
        />
      )}
      {!data.followedLists.some((l) => l.id === list.id) && (
        <Button
          as="a"
          content="Follow List"
          className="basic-btn"
          onClick={() => followList({ variables: { listId: list.id } })}
        />
      )}
    </>
  );
};

export default FollowListButton;
