import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from '../components/Loading';
import ErrorPopup from '../components/ErrorPopup';

const AuthContext = React.createContext({ token: null });
export const AuthProvider = ({ children }) => {
  const {
    isLoading,
    isAuthenticated,
    getIdTokenClaims,
    error,
    loginWithRedirect,
    loginWithPopup,
    logout,
    user,
  } = useAuth0();

  // A user will have a token and Auth0 profile after signing up with Auth0,
  // even if their email is not verified
  const [token, setToken] = useState(null);

  const tokenNeeded = isAuthenticated && !token;

  const signout = () => logout({
    logoutParams: { returnTo: window.location.origin },
  });

  if (tokenNeeded) {
    getIdTokenClaims().then((t) => {
      setToken(t.__raw);
    });
  }

  if (isLoading || tokenNeeded) {
    return <Loading />;
  }

  return (
    <AuthContext.Provider
      value={{
        token, loginWithRedirect, logout: signout, loginWithPopup, auth0User: user,
      }}
    >
      {error && (
        <ErrorPopup
          content={error.message}
          onClose={signout}
        />
      )}
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
