import React from 'react';
import { Header, Input } from 'semantic-ui-react';

const LeadTime = ({ settings, setSettings }) => (
  <div className="additional-setting">
    <Header>
      Lead time
      <Header.Subheader>
        Set the minimum amount of lead time that is required
      </Header.Subheader>
    </Header>
    <div>
      {'Experts can\'t schedule within '}
      <Input
        size="small"
        type="number"
        min={0}
        value={settings.leadHours}
        onChange={(e, { value }) => setSettings({ ...settings, leadHours: value })}
      />
      {' hours of an event start time.'}
    </div>
  </div>
);

export default LeadTime;
