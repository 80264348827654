import React, { useState, useContext } from 'react';
import { useQuery } from '@apollo/client';
import {
  Grid, Modal, Divider, Loader,
} from 'semantic-ui-react';
import Heading from './Heading';
import Content from './Content';
import EXPERT_PROFILE from '../../../queries/expert';
import MixpanelTracker from '../../../utils/MixpanelTracker';
import SearchContext from '../../../contexts/SearchContext';

const ProfileModal = ({ expertId, children }) => {
  const searchContext = useContext(SearchContext);
  const [open, setOpen] = useState(false);

  const { loading, data } = useQuery(EXPERT_PROFILE, {
    skip: !open,
    variables: { id: expertId },
    onCompleted: ({ expert }) => {
      if (searchContext.searchResult) {
        MixpanelTracker.trackViewExpertProfile(expert, searchContext);
      }
    },
  });

  return (
    <Modal
      closeIcon
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      trigger={children}
      size="large"
      id="profile-modal"
    >
      <Modal.Content>
        <Loader active={loading} />
        {data && (
          <Grid>
            <Grid.Row columns={2} className="heading">
              <Heading expert={data.expert} />
            </Grid.Row>
            <Divider />
            <Grid.Row columns={2} className="content">
              <Content expert={data.expert} />
            </Grid.Row>
          </Grid>
        )}
      </Modal.Content>
    </Modal>
  );
};

export default ProfileModal;
