import React from 'react';
import {
  GridColumn, Form, GridRow, FormField, Checkbox, Radio, Popup, Icon,
} from 'semantic-ui-react';

const Choices = ({ question, updateQuestion }) => {
  const {
    id, answerChoices, questionType, otherIncluded,
  } = question;
  return (
    <>
      {answerChoices.map((answerChoice, choiceIndex) => (
        <GridRow
          className="answer-choices"
          key={`${id + choiceIndex}`}
        >
          <GridColumn verticalAlign="middle" className="answer-choice-field">
            <FormField
              control={questionType === 'checkbox' ? Checkbox : Radio}
              readOnly
              label=""
            />
          </GridColumn>
          <GridColumn width={4} verticalAlign="middle">
            <Form.Input
              value={answerChoice}
              onChange={
                       (e, { value }) => {
                         const tempArray = [...answerChoices];
                         tempArray[choiceIndex] = value;
                         updateQuestion(id, { answerChoices: tempArray });
                       }
  }
            />
          </GridColumn>
          <GridColumn width={4} verticalAlign="middle">
            {answerChoices.length > 1 && (
              <Popup
                content="Delete"
                trigger={(
                  <Icon
                    name="trash alternate outline"
                    className="answer-choice-btn"
                    size="large"
                    onClick={() => {
                      const newAnswerChoices = [...answerChoices];
                      newAnswerChoices.splice(choiceIndex, 1);
                      updateQuestion(id, { answerChoices: newAnswerChoices });
                    }}
                  />
                          )}
              />
            )}
            {choiceIndex === answerChoices.length - 1 && (
              <Popup
                content="Add another"
                trigger={(
                  <Icon
                    name="plus square outline"
                    className="answer-choice-btn"
                    size="large"
                    onClick={() => updateQuestion(id, { answerChoices: answerChoices.concat('') })}
                  />
  )}
              />
            )}

          </GridColumn>
        </GridRow>

      ))}
      <GridRow className="answer-choices other-included">
        <GridColumn>
          <Form.Checkbox
            control={Checkbox}
            label="Include 'Other' option"
            checked={otherIncluded}
            onChange={
                                  (e, { checked }) => updateQuestion(id, { otherIncluded: checked })
  }
          />
        </GridColumn>
      </GridRow>
    </>
  );
};

export default Choices;
