import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  Form, Icon, Input, Button,
} from 'semantic-ui-react';

const SearchExpertForm = ({ defaultQuery = null }) => {
  const history = useHistory();
  const params = new URLSearchParams(useLocation().search);
  const queryFromURL = params.get('query');
  const [query, setQuery] = useState('');

  // Updates the search bar input value based on the URL when query changes
  useEffect(() => {
    setQuery(params.get('query') || defaultQuery || '');
  }, [queryFromURL]);

  return (
    <Form
      onSubmit={() => {
        history.push({ pathname: '/search', search: `?query=${query}` });
      }}
    >
      <Input
        fluid
        placeholder="Search, e.g. 'liquid biopsy', 'single cell', 'crispr'"
        className="nav-item"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        label={(<Button icon><Icon name="search" /></Button>)}
        labelPosition="right"
      />
    </Form>

  );
};

export default SearchExpertForm;
