import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  Segment, Form, Button, Transition, Icon, Message,
} from 'semantic-ui-react';
import PROFILE, { UPDATE_USER } from '../../queries/profile';

const Profile = ({ user }) => {
  const [firstName, setFirstName] = useState(user.firstName || '');
  const [lastName, setLastName] = useState(user.lastName || '');
  const [isSaved, setIsSaved] = useState(false);

  const [updateUser, { loading }] = useMutation(UPDATE_USER, {
    update(cache, { data }) {
      cache.writeQuery({
        query: PROFILE,
        data: { me: data.updateUser },
      });
    },
    onCompleted: () => {
      setIsSaved(true);
      setTimeout(() => setIsSaved(false), 3000);
    },
  });

  const onSave = () => {
    updateUser({
      variables: { input: { firstName, lastName } },
    });
  };

  return (
    <div id="profile">
      <Segment>
        <Transition visible={isSaved} animation="scale" duration={500}>
          <Message success floating>
            <Message.Header>
              <Icon name="check" />
              Saved
            </Message.Header>
          </Message>
        </Transition>
        <Form>
          <Form.Group widths="equal">
            <Form.Input
              fluid
              label="First name"
              value={firstName}
              onChange={(e, { value }) => setFirstName(value)}
            />
            <Form.Input
              fluid
              label="Last name"
              value={lastName}
              onChange={(e, { value }) => setLastName(value)}
            />
          </Form.Group>
        </Form>
      </Segment>
      <Button
        className="primary-btn"
        floated="right"
        loading={loading}
        disabled={loading}
        onClick={onSave}
      >
        Save Updates
      </Button>
    </div>
  );
};

export default Profile;
