import React, { useState, useContext } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
  Modal, Button, Divider, Form, Header, Loader, Icon,
} from 'semantic-ui-react';
import { EXTERNAL_ACCOUNT_CALENDARS, UPDATE_EXTERNAL_CALENDAR_SUBSCRIPTION } from '../../../queries/externalAccount';
import DataContext from '../../../contexts/DataContext';

const ConfigureButton = ({ account, reconnectExternalCalendar }) => {
  const { profileRefetch } = useContext(DataContext);
  const [open, setOpen] = useState(false);
  const [subscribedCalendars, setSubscribedCalendars] = useState(account.subscribedCalendars);

  const { data, loading, error } = useQuery(EXTERNAL_ACCOUNT_CALENDARS, {
    variables: { id: account.id },
    skip: !open,
    onError: (err) => {
      if (err.message === 'invalid_grant') {
        setOpen(false);
        reconnectExternalCalendar(account);
        // Update the external account because the status is changed
        profileRefetch();
      }
    },
  });
  const [updateExternalCalendarSubscription, { loading: saving }] = useMutation(
    UPDATE_EXTERNAL_CALENDAR_SUBSCRIPTION, {
      onCompleted: () => setOpen(false),
    },
  );

  const saveSubscribedCalendars = () => {
    updateExternalCalendarSubscription({
      variables: {
        externalAccountId: account.id,
        calendarIds: subscribedCalendars.map((c) => c.calendarId),
      },
    });
  };

  const renderCalendars = () => {
    if (loading) return <Loader active inline />;
    if (error) return <Header as="h5" textAlign="center" color="red">{error.message}</Header>;
    return data.externalAccount.calendars.map((cal) => {
      const isPrimary = cal.calendarId === 'primary';
      const subscribed = subscribedCalendars.some((subCal) => subCal.calendarId === cal.calendarId);
      return (
        <Form.Checkbox
          key={cal.calendarId}
          label={`${cal.name} ${isPrimary ? '(primary)' : ''}`}
          defaultChecked={isPrimary || subscribed}
          disabled={isPrimary}
          onClick={(e, { checked }) => {
            if (checked) {
              setSubscribedCalendars(subscribedCalendars.concat(cal));
            } else {
              setSubscribedCalendars(
                subscribedCalendars.filter((subCal) => subCal.calendarId !== cal.calendarId),
              );
            }
          }}
        />
      );
    });
  };

  return (
    <>
      <Modal
        closeIcon
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
      >
        <Modal.Header>How should we determine your availability?</Modal.Header>
        {open && (
          <Modal.Content>
            <Header textAlign="center" color="grey">
              Check these calendar(s) for availability
            </Header>
            <Header textAlign="center" color="grey">
              <Icon name="google" />
              {account.accountId}
            </Header>
            <Divider />
            <Form loading={loading}>{renderCalendars()}</Form>
          </Modal.Content>
        )}
        <Modal.Actions>
          <Button className="basic-btn" onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            loading={saving}
            disabled={saving}
            className="primary-btn"
            onClick={saveSubscribedCalendars}
          >
            Save
          </Button>
        </Modal.Actions>
      </Modal>
      <Button size="small" className="primary-btn" onClick={() => setOpen(true)}>Configure</Button>
    </>

  );
};

export default ConfigureButton;
