import React from 'react';
import { Segment, Image } from 'semantic-ui-react';

const Welcome = () => (
  <Segment id="welcome">
    <Image
      centered
      size="big"
      src={`${process.env.REACT_APP_AWS_S3_URL}/welcome.png`}
    />
    <p>
      Dexter is designed with your unique voice-of-customer research needs in mind.
      Focus your time & energy on getting answers to your important business questions
      and leave everything else to us!
    </p>
  </Segment>
);

export default Welcome;
