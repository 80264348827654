import React from 'react';
import { Form } from 'semantic-ui-react';
import moment from 'moment';

const DateSelector = ({ timezone, daysFromNow, setDaysFromNow }) => {
  const today = moment().tz(timezone);
  const dateOptions = Array.from({ length: 5 }, (_, index) => {
    const selectedDate = moment(today).add(index, 'days');
    return {
      id: index,
      value: index,
      text: selectedDate.format('dddd, MMMM D'),
    };
  });
  return (
    <Form>
      <Form.Dropdown
        label="Select a date"
        placeholder="Select a date"
        selection
        value={daysFromNow}
        options={dateOptions}
        onChange={(e, { value }) => setDaysFromNow(value)}
      />
    </Form>
  );
};

export default DateSelector;
