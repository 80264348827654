import React from 'react';
import { Confirm, Header, Button } from 'semantic-ui-react';

const SuccessPopup = ({ title, content, onClose }) => (
  <Confirm
    open
    closeIcon
    header="Success"
    content={(
      <div style={{ padding: '1.5rem' }}>
        <Header>{title}</Header>
        <p>{content}</p>
      </div>
    )}
    onConfirm={onClose}
    cancelButton={null}
    confirmButton={<Button className="primary-btn">Done</Button>}
  />
);

export default SuccessPopup;
