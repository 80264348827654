import React, { useState, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { Modal, Button, Header } from 'semantic-ui-react';
import MessageContext from '../../../contexts/MessageContext';
import { MANAGED_PROJECTS, UPCOMING_MEETINGS } from '../../../queries/profile';
import { PROJECT_JOBS } from '../../../queries/project';
import { UPDATE_JOB } from '../../../queries/job';

const CancelRequest = ({ job, children }) => {
  const [open, setOpen] = useState(false);
  const { setSuccessPopup } = useContext(MessageContext);

  const { project, user } = job;

  const [cancelRequest, { loading }] = useMutation(UPDATE_JOB, {
    refetchQueries: [
      { query: UPCOMING_MEETINGS },
      { query: MANAGED_PROJECTS },
      { query: PROJECT_JOBS, variables: { id: project.id } },
    ],
    onCompleted: () => {
      setSuccessPopup({
        title: `Meeting Invitation to ${user.fullName} has been canceled`,
        content: `The invitation status has been updated to "Expired", Please note that the
          expert will not be able to respond to this invitation`,
        onClose: () => setOpen(false),
      });
    },
  });

  return (
    <Modal
      closeIcon
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      trigger={children}
      className="setting-modal-layout"
    >
      <Modal.Header>Cancel Meeting Request</Modal.Header>
      <Modal.Content>
        <Header>
          {`Your invitation to ${user.fullName} on ${project.title} will be canceled`}
        </Header>
        <p>
          The invitation status will update to &quot;Expired&quot;. The expert will no longer be
          able to respond to this invitation
        </p>
      </Modal.Content>
      <Modal.Actions>
        <span className="subtext">
          Once canceled, you cannot re-invite the same expert to this project
        </span>
        <Button className="basic-btn" onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button
          loading={loading}
          disabled={loading}
          className="primary-btn"
          onClick={() => cancelRequest({
            variables: {
              userId: user.id,
              projectId: project.id,
              job: { overall_status: 'Expired' },
            },
          })}
        >
          Cancel Request
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default CancelRequest;
