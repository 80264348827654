import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  Accordion,
  Icon,
  Form,
  Button,
  Message,
  Header,
  Transition,
} from 'semantic-ui-react';
import AvailabilityView from './AvailabilityView';
import AttendanceRequirement from './AttendanceRequirement';
import LeadTime from './LeadTime';
import DateRange from './DateRange';
import BufferTime from './BufferTime';
import { UPDATE_PROJECT } from '../../../../queries/project';

const Scheduling = ({ project }) => {
  const expanded = project.owners.length > 1;
  const [prevExpanded, setPrevExpanded] = useState(expanded);
  const [open, setOpen] = useState(expanded);
  const [isSaved, setIsSaved] = useState(false);
  const [schedulingSettings, setSchedulingSettings] = useState(project.schedulingSettings);
  const [updateProject, { loading, error }] = useMutation(UPDATE_PROJECT, {
    onCompleted: () => {
      setIsSaved(true);
      setTimeout(() => setIsSaved(false), 3000);
    },
  });

  if (prevExpanded !== expanded) {
    setOpen(expanded);
    setPrevExpanded(expanded);
  }

  return (
    <Accordion>
      <Accordion.Title
        active={open}
        onClick={() => setOpen(!open)}
      >
        Additional scheduling settings
        <Icon name="dropdown" />
      </Accordion.Title>
      <Accordion.Content active={open}>
        <Form
          className="accordion-content"
          onSubmit={() => updateProject({
            variables: {
              id: project.id,
              input: {
                schedulingSettings: {
                  attendanceRequirement: schedulingSettings.attendanceRequirement,
                  leadHours: parseInt(schedulingSettings.leadHours, 10),
                  bufferMinutes: parseInt(schedulingSettings.bufferMinutes, 10),
                  availableDays: parseInt(schedulingSettings.availableDays, 10),
                  startDate: schedulingSettings.startDate,
                  endDate: schedulingSettings.endDate,
                },
              },
            },
          })}
        >
          {error && <Message error={error !== null} content={error.message} />}
          <Header.Subheader className="current-availability">
            {'These settings affect when experts can schedule meetings, '}
            <AvailabilityView project={project} />
          </Header.Subheader>
          <AttendanceRequirement
            settings={schedulingSettings}
            setSettings={setSchedulingSettings}
          />
          <LeadTime settings={schedulingSettings} setSettings={setSchedulingSettings} />
          <DateRange settings={schedulingSettings} setSettings={setSchedulingSettings} />
          <BufferTime settings={schedulingSettings} setSettings={setSchedulingSettings} />
          {isSaved && (
          <Transition visible={isSaved} animation="scale" duration={500}>
            <Header color="green">Successfully saved!</Header>
          </Transition>
          )}
          <Button
            className="primary-btn"
            loading={loading}
            disabled={loading}
          >
            Save
          </Button>
        </Form>
      </Accordion.Content>
    </Accordion>
  );
};

export default Scheduling;
