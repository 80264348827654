import React, { useContext } from 'react';
import FilterCategory from './FilterCategory';
import DataContext from '../../contexts/DataContext';
import SearchContext from '../../contexts/SearchContext';

const ProjectExclusion = () => {
  const { managedProjects } = useContext(DataContext);
  const { toggleFilter, appliedExclusions } = useContext(SearchContext);
  const exclusionSelectable = managedProjects.filter((p) => p.status !== 'Completed');
  if (exclusionSelectable.length === 0) return '';

  const items = exclusionSelectable.map((p) => ({
    name: p.title,
    value: p.id,
    count: -1,
  }));
  const facet = { categoryId: 'projects', name: 'Exclude from project', items };

  let selected = appliedExclusions.filter((xcl) => xcl.categoryId === 'projects');
  if (selected.length > 0) {
    selected = selected[0].value;
  }

  return (
    <div className="filter-category" key="xclProject">
      <FilterCategory
        facet={facet}
        selected={selected}
        toggleFilter={toggleFilter}
      />
    </div>
  );
};

export default ProjectExclusion;
