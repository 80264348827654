import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Header, Modal, Button, Segment, Grid,
} from 'semantic-ui-react';
import SearchExpertForm from '../SearchExpertForm';

const InterviewProjectSuccessContent = ({ keywords }) => (
  <>
    <Modal.Header>What&apos;s Next</Modal.Header>
    <Modal.Content>
      <Header>
        Start searching for relevant experts using the search bar
      </Header>
      <SearchExpertForm defaultQuery={keywords.join(', ')} />
    </Modal.Content>
  </>
);

const SurveyProjectSuccessContent = ({ qualtricsSurvey }) => {
  const { qualtricsUsers } = qualtricsSurvey;
  const { username, password } = qualtricsUsers[0];
  return (
    <>
      <Modal.Header>Congratulation on creating your survey project!</Modal.Header>
      <Modal.Content>
        <Header>Your survey is ready in Qualtrics</Header>
        <p>Please sign in to the Qualtrics account with the following information.</p>
        {password && (
          <p>
            We&apos;ve also sent you an email with the credentials and instructions
            on signing in with this free Qualtrics account.
          </p>
        )}
        <Segment placeholder className="qualtrics-credentials-box">
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Header>
                  Your Qualtrics Account with Dexter
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={4}>
              <Grid.Column>
                <Header as="h4">Username</Header>
              </Grid.Column>
              <Grid.Column>{username}</Grid.Column>
            </Grid.Row>
            {password && (
              <Grid.Row columns={4}>
                <Grid.Column>
                  <Header as="h4">Temporary Password</Header>
                </Grid.Column>
                <Grid.Column>{password}</Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        </Segment>
        <p>
          After signing in, you will find your survey project ready for editing.
        </p>
        <Header as="h4">
          You can only start inviting experts once you&apos;ve published your survey in Qualtrics!
        </Header>
      </Modal.Content>
    </>
  );
};

const ProjectCreationSuccessPopup = ({ project, onClose }) => {
  const history = useHistory();
  const {
    id, type, keywords, qualtricsSurvey,
  } = project;
  const closeAndRedirect = () => {
    if (history.location.pathname === '/projects/new') {
      history.push(`/projects/${id}`);
    }
    onClose();
  };
  return (
    <Modal
      closeIcon
      open
      onClose={closeAndRedirect}
      id="project-creation-success-popup"
    >
      {type === 'Interview' ? (
        <InterviewProjectSuccessContent keywords={keywords} />
      ) : (
        <SurveyProjectSuccessContent qualtricsSurvey={qualtricsSurvey} />
      )}
      <Modal.Actions>
        <Button className="primary-btn" onClick={closeAndRedirect}>Got it!</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ProjectCreationSuccessPopup;
