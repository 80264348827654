import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Dropdown } from 'semantic-ui-react';
import momentTZ from 'moment-timezone';
import PROFILE, { UPDATE_USER } from '../../../queries/profile';

const Timezone = ({ userTimezone }) => {
  const [timeZone, setTimeZone] = useState(userTimezone);

  const [updateUser] = useMutation(UPDATE_USER, {
    update(cache, { data }) {
      cache.writeQuery({
        query: PROFILE,
        data: { me: data.updateUser },
      });
    },
  });

  const onSave = (newTimezone) => {
    setTimeZone(newTimezone);
    updateUser({
      variables: { input: { timeZone: newTimezone } },
    });
  };

  // Get a list of valid timezones in different cities for each country
  const timezonesFromCountries = [...new Set(momentTZ.tz.countries()
    .map((country) => momentTZ.tz.zonesForCountry(country))
    .flat(1))];

  const TZ_OPTIONS = timezonesFromCountries.map((timezone) => {
    const currentTime = momentTZ.tz(timezone).format('LT');
    const offset = momentTZ.tz(timezone).format('z, Z')
      .replace(/\w+,/g, '')
      .replace(/\s+/g, '')
      .replace(/\+{2}/g, '+');
    return {
      key: timezone,
      value: timezone,
      offset,
      text: `(GMT ${offset}) ${timezone} (${currentTime})`,
    };
  });

  TZ_OPTIONS.sort((a, b) => (a.offset > b.offset ? 1 : -1));

  return (
    <Dropdown
      fluid
      placeholder="Select your timezone"
      search
      selection
      value={timeZone}
      options={TZ_OPTIONS}
      onChange={(e, { value }) => onSave(value)}
    />
  );
};

export default Timezone;
