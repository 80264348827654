import React, { useState } from 'react';
import {
  Icon, Divider, Grid, Header, List, Form,
} from 'semantic-ui-react';

const FilterCategory = ({
  facet, selected, toggleFilter,
}) => {
  const [expanded, setExpanded] = useState(selected.length > 0);
  const { categoryId, name: categoryName, items } = facet;

  const toggleExpanded = (e) => {
    e.preventDefault();
    setExpanded(!expanded);
  };

  // Put checked items at the top
  items.sort((a, b) => {
    const aIncluded = selected.includes(a.value);
    const bIncluded = selected.includes(b.value);

    if (aIncluded !== bIncluded) {
      return aIncluded ? -1 : 1;
    }

    return b.count - a.count;
  });

  const renderOptions = () => (
    <Form>
      {items.map((y) => (
        <List.Item key={y.value}>
          <Grid.Row>
            <Form.Checkbox
              label={`${y.name ? y.name : y.value}${y.count !== -1 ? ` ${y.count}` : ''}`}
              checked={selected.includes(y.value)}
              onChange={() => {
                toggleFilter(categoryId, y.value);
              }}
            />
          </Grid.Row>
        </List.Item>
      ))}
    </Form>
  );

  return (
    <>
      <Grid.Row className="category-name span-link" onClick={(e) => toggleExpanded(e)}>
        <Header>
          {categoryName}
          <Icon name={expanded ? 'angle up' : 'angle down'} fitted />
        </Header>
      </Grid.Row>
      <Grid.Row className="category-content">
        {expanded && <List className="category-options">{renderOptions()}</List>}
      </Grid.Row>
      <Divider />
    </>
  );
};

export default FilterCategory;
