import React from 'react';
import { Icon } from 'semantic-ui-react';

const ProfileLabel = ({ num = 0, type = 'credit' }) => (
  <span className="credit-label">
    {
      type === 'credit'
        ? <img src={`${process.env.REACT_APP_AWS_S3_URL}/creditIcon.png`} alt="credit icon" />
        : <Icon name="call square" size="large" />
    }
    {num}
    {type === 'credit' ? ' credit(s)' : ' Completed call(s)'}
  </span>
);

export default ProfileLabel;
