import React from 'react';
import {
  Form, Header, Divider, Segment, List, Label, Icon,
} from 'semantic-ui-react';

const SAMPLE_KEYWORDS = ['Oncology', 'Pathology', 'Translational Medicine', 'Liquid Biopsy', 'Infectious Disease', 'Genomics', 'Single Cell'];

const Keywords = ({
  project, updateProject,
}) => {
  const { keywords } = project;
  return (
    <div id="project-keyword-form">
      <div>
        <strong className="project-title">Project Keywords</strong>
      </div>
      <Divider />
      <Header.Subheader>
        Enter project keywords that describe the expertise or topics related to your
        project to quickly search for relevant experts that are the
        best fit for this project
      </Header.Subheader>

      <Form.Dropdown
        placeholder="Enter project keywords"
        icon={null}
        selection
        search
        multiple
        allowAdditions
        noResultsMessage={null}
        options={keywords.map((value) => ({ key: value, text: value, value }))}
        value={keywords}
        onChange={(e, { value }) => {
          const trimmedUniqueKeywords = [...new Set(value.map((keyword) => keyword.trim()))];
          updateProject({ keywords: trimmedUniqueKeywords });
        }}
      />
      <Segment placeholder className="sample-keywords">
        <Header>Sample keywords</Header>
        <List horizontal>
          {SAMPLE_KEYWORDS.map((k) => (
            <List.Item key={k}>
              <Label
                as="a"
                basic
                onClick={() => {
                  updateProject({ keywords: [...new Set(keywords.concat(k))] });
                }}
              >
                <Icon name="plus" />
                {k}
              </Label>
            </List.Item>
          ))}
        </List>
      </Segment>
    </div>
  );
};

export default Keywords;
