export const LIST_PRIVACY_OPTIONS = {
  private: {
    icon: 'lock',
    dropdown: 'Private',
    description: 'Only I can view this list',
  },
  team: {
    icon: 'group',
    dropdown: 'Team',
    description: 'Team members can view this list',
  },
  public: {
    icon: 'globe',
    dropdown: 'Anyone with the link',
    description: 'A registered user with the link can view and follow this list',
  },
};
export default LIST_PRIVACY_OPTIONS;
