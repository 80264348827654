import React from 'react';
import {
  Segment, Header, Divider,
} from 'semantic-ui-react';
import Timezone from './Timezone';
import WeeklyHours from './WeeklyHours';

const AvailableTimeBlocks = ({ user }) => (
  <Segment id="availability">
    <div className="timezone">
      <Header>
        My Time Zone
      </Header>
      <Timezone userTimezone={user.timeZone} />
    </div>
    <div className="weeklyhours">
      <Header>
        My Daily Availability
      </Header>
      <i>
        Set which hours you’re available each day. E.g., If you only want to
        conduct interviews after 10:00am on weekdays, or if you want to be
        finished by 3:00pm on Fridays.
      </i>
      <Divider />
      <WeeklyHours userAvailability={user.availability} />
    </div>
  </Segment>
);

export default AvailableTimeBlocks;
