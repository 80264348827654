import React, { useState, useContext } from 'react';
import { useMutation } from '@apollo/client';
import {
  Modal, Form, Button, Header,
} from 'semantic-ui-react';
import MessageContext from '../../contexts/MessageContext';
import { MANAGED_PROJECTS, UPCOMING_MEETINGS } from '../../queries/profile';
import { PROJECT_JOBS } from '../../queries/project';
import { CANCEL_JOB } from '../../queries/meeting';

const CancelJob = ({ job, children }) => {
  const [open, setOpen] = useState(false);
  const [reason, setReason] = useState('');
  const { setSuccessPopup, setErrorPopup } = useContext(MessageContext);

  const {
    id, project, user, interviewers,
  } = job;

  const [cancelJob, { loading }] = useMutation(CANCEL_JOB, {
    refetchQueries: [
      { query: UPCOMING_MEETINGS },
      { query: MANAGED_PROJECTS },
      { query: PROJECT_JOBS, variables: { id: project.id } },
    ],
    onCompleted: () => {
      setSuccessPopup({
        title: `${project.title} with ${user.fullName} has been canceled`,
        content: `This event will be removed from  your calendar and upcoming meetings.
          Please head over to the Settings > Availability page if you need to update your availability.`,
        onClose: () => setOpen(false),
      });
    },
    onError: (err) => {
      setErrorPopup({
        title: 'Failed to cancel this meeting',
        content: err.message,
        onClose: () => setOpen(false),
      });
    },
  });

  const interviewerNames = interviewers.map((i) => i.fullName || i.email);

  return (
    <Modal
      closeIcon
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      trigger={children}
      className="setting-modal-layout"
    >
      <Modal.Header>{project.type === 'Interview' ? 'Cancel Meeting' : 'Cancel Invite'}</Modal.Header>
      <Modal.Content>
        <Header>
          {`You are about to cancel ${project.title} with ${user.fullName}`}
        </Header>
        <Form>
          <Form.TextArea
            label="Please provide a brief explanation below"
            value={reason}
            onInput={(e, { value }) => setReason(value)}
          />
        </Form>
        <Header as="h5">
          {`Warning! You are about to cancel this interview with the following interviewer(s): ${interviewerNames.join(', ')}.
          Are you sure?`}
        </Header>
      </Modal.Content>
      <Modal.Actions>
        <span className="subtext">
          Once canceled, you cannot re-invite the same expert to this project
        </span>
        <Button
          loading={loading}
          disabled={!reason || loading}
          className="primary-btn"
          onClick={() => cancelJob({ variables: { jobId: id, reason } })}
        >
          {project.type === 'Interview' ? 'Cancel Meeting' : 'Cancel Invite'}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default CancelJob;
