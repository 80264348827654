import React, { useState, useContext } from 'react';
import { useQuery } from '@apollo/client';
import {
  Segment, Grid, Divider, Header,
} from 'semantic-ui-react';
import Loading from '../../Loading';
import ExportExpertButton from './ExportExpertButton';
import DateSelector from './DateSelector';
import ProjectResponseForecast from './ProjectResponseForecast';
import FilterByCategory from './FilterByCategory';
import ExpertFilters from './ExpertFilters';
import { PROJECT_JOBS } from '../../../queries/project';
import DataContext from '../../../contexts/DataContext';

const TrackProjectProgress = ({ project }) => {
  const { user } = useContext(DataContext);
  const [daysFromNow, setDaysFromNow] = useState(0);
  const [categoryFilters, setCategoryFilters] = useState({});

  const { loading, data } = useQuery(PROJECT_JOBS, {
    variables: { id: project.id },
  });
  if (loading) return <Loading />;

  const updateFilters = (value, category) => {
    const tempFilters = { ...categoryFilters };
    if (tempFilters[category] === value) {
      delete tempFilters[category];
    } else {
      tempFilters[category] = value;
    }
    setCategoryFilters(tempFilters);
  };

  return (
    <Segment id="project-progress-tracker">
      <Grid container stackable>
        <Grid.Row className="header-section">
          <Grid.Column>
            <Header>Project Forecast</Header>
            <ExportExpertButton jobs={data.project.jobs} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="date-section">
          <DateSelector
            timezone={user.timeZone}
            daysFromNow={daysFromNow}
            setDaysFromNow={setDaysFromNow}
          />
        </Grid.Row>
        <Grid.Row className="response-forecast-section">
          <ProjectResponseForecast
            timezone={user.timeZone}
            jobs={data.project.jobs}
            daysFromNow={daysFromNow}
          />
        </Grid.Row>
        <Divider />
        <Grid.Row className="header-section">
          <Grid.Column>
            <Header>Demographic Distribution</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="filters-section">
          <Grid.Column width={11}>
            <FilterByCategory
              timezone={user.timeZone}
              daysFromNow={daysFromNow}
              jobs={data.project.jobs}
              categoryFilters={categoryFilters}
              updateFilters={updateFilters}
              resetFilters={() => setCategoryFilters({})}
            />
          </Grid.Column>
          <Grid.Column width={5}>
            <ExpertFilters
              project={data.project}
              categoryFilters={categoryFilters}
              updateFilters={updateFilters}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default TrackProjectProgress;
