import React from 'react';
import { ResponsiveBar } from '@nivo/bar';

const BarChart = ({
  data, displayBarOnly, disabled, width = '100%',
}) => {
  // TODO: make sure color constants are consistent across all project tabs
  const BAR_COLORS = {
    Completed: 'rgb(144, 205, 153)',
    Scheduled: 'rgb(101, 128, 219)',
    'Predicted*': 'rgb(173, 187, 234)',
  };

  const getBarData = () => {
    const barData = {};

    const keyMappings = {
      numCompleted: 'Completed',
      numScheduled: 'Scheduled',
      numPredicted: 'Predicted*',
    };

    Object.entries(data).forEach(([dataKey, value]) => {
      const barKey = keyMappings[dataKey];
      // To prevent the label 0 from showing up on the bar
      if (barKey && value !== 0) {
        barData[barKey] = value;
      }
    });
    return [barData];
  };

  return (
    <div
      style={{
        width,
        height: displayBarOnly ? 30 : 150,
        opacity: disabled ? '50%' : '100%',
      }}
      className="bar-chart"
    >
      <ResponsiveBar
        data={getBarData()}
        keys={['Completed', 'Scheduled', 'Predicted*']}
        padding={displayBarOnly ? 0 : 0.6}
        layout="horizontal"
        colors={(bar) => BAR_COLORS[bar.id]}
        axisLeft={null}
        axisBottom={null}
        labelTextColor="#000"
        legends={displayBarOnly ? undefined : [
          {
            dataFrom: 'keys',
            anchor: 'top-right',
            direction: 'row',
            translateX: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 25,
          },
        ]}
        isInteractive={false}
      />
    </div>
  );
};

export default BarChart;
