import React, { useState } from 'react';
import { Modal, Icon } from 'semantic-ui-react';
import ProjectNew from '../../pages/ProjectNew';
import CustomLink from '../CustomLink';

const ProjectNewButton = () => {
  const [openCreate, setOpenCreate] = useState(false);
  return (
    <>
      {openCreate && (
        <Modal
          closeIcon
          open
          onClose={() => setOpenCreate(false)}
          size="large"
          id="create-project-modal"
        >
          <ProjectNew
            onCreate={() => setOpenCreate(false)}
            onCancel={() => setOpenCreate(false)}
          />
        </Modal>
      )}
      <CustomLink onClick={() => setOpenCreate(true)}>
        <Icon name="plus" />
        Create a new project
      </CustomLink>
    </>

  );
};

export default ProjectNewButton;
