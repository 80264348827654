import React from 'react';
import { Header, Button } from 'semantic-ui-react';
import { QUALTRICS_SURVEY_URL } from '../../constants/qualtrics';
import CustomLink from '../CustomLink';

const QUALTRICS_SCREENOUT_INSTRUCTION_LINK = 'https://docs.google.com/document/d/1b1_BCmSah-CR-_s8Xj9qs5gdJ2QiWVIrnKSMPIhqJw0/edit?pli=1';

const QualtricsSurveyAccess = ({ qualtricsSurvey, showWarning = false }) => {
  const { surveyId, status } = qualtricsSurvey;
  const renderWarning = () => {
    if (status === 'New') {
      return (
        <Header color="red">
          Qualtrics survey not published - You can only start inviting experts
          once you&apos;ve published your survey in Qualtrics!
        </Header>
      );
    }
    if (status === 'Invalid Screening') {
      return (
        <Header color="red" className="invalid-screening-warning">
          <p>
            It seems that your Qualtrics survey includes screening questions.
          </p>
          {'If that\'s the case, please follow the instructions provided '}
          <CustomLink onClick={() => window.open(QUALTRICS_SCREENOUT_INSTRUCTION_LINK, '_blank')}>
            here
          </CustomLink>
          {' to correctly program them.'}
          <p>This will ensure that you avoid being charged for responses that are screened out.</p>
        </Header>
      );
    }
    return <div />;
  };
  return (
    <div id="qualtrics-survey-access">
      {showWarning && renderWarning()}
      <Header>Qualtrics survey</Header>
      <Button
        className="primary-btn"
        onClick={() => window.open(`${QUALTRICS_SURVEY_URL}/${surveyId}/edit`, '_blank')}
      >
        Click to edit survey
      </Button>
    </div>
  );
};

export default QualtricsSurveyAccess;
