import React from 'react';
import { Header, Label, Segment } from 'semantic-ui-react';

const TAGS = [
  'Liquid Biopsy', 'Single Cell', 'CRISPR',
  'Digital Pathology', 'Oncology', 'Gene Therapy',
  'MRD Testing', 'Ob/Gyn', 'COVID-19',
];

const AllSet = ({ onExitTour }) => (
  <Segment id="allset">
    <Header>Popular Expert Categories</Header>
    <div className="category-section">
      {TAGS.map((t) => (
        <Label
          key={t}
          size="huge"
          as="a"
          basic
          onClick={() => {
            onExitTour({ pathname: '/search', search: `?query=${t}` });
          }}
        >
          {t}
        </Label>
      ))}
    </div>
  </Segment>
);

export default AllSet;
