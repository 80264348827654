import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import {
  Header, Icon, Button, Form, Confirm, Message,
} from 'semantic-ui-react';
import { DELETE_LIST, MY_LISTS, UPDATE_LIST } from '../../queries/list';
import DataContext from '../../contexts/DataContext';

const ListName = ({ list }) => {
  const history = useHistory();
  const { user, myLists } = useContext(DataContext);
  const [editing, setEditing] = useState(false);
  const [name, setName] = useState(list.name);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  const [deleteList] = useMutation(DELETE_LIST, {
    onCompleted: () => {
      setEditing(false);
      setDeleteConfirmOpen(false);
      const favoritesList = myLists.find((l) => l.name === 'Favorites');
      history.push(`/lists/${favoritesList.id}`);
    },
    refetchQueries: [{ query: MY_LISTS }],
  });

  const [updateList, { error }] = useMutation(UPDATE_LIST, {
    onCompleted: () => {
      setEditing(false);
    },
  });

  useEffect(() => {
    setEditing(false);
    setName(list.name);
  }, [list]);

  return (
    <div id="list-name">
      {!editing && (
        <div className="before-edit">
          <Header>{list.name}</Header>
          {(list.name !== 'Favorites') && (user.id === list.user.id) && (
            <Icon
              name="pencil"
              onClick={() => setEditing(true)}
            />
          )}
        </div>
      )}
      {editing && (
        <Form
          onSubmit={() => updateList({ variables: { id: list.id, input: { name } } })}
          error={error !== null}
          className="edit"
        >
          <Form.Group>
            <Form.Input
              value={name}
              onChange={(e, { value }) => setName(value)}
              width={7}
            />
            <Button
              as="a"
              className="basic-btn"
              onClick={() => {
                setName(list.name);
                setEditing(false);
              }}
            >
              Cancel
            </Button>
            <Button
              color="red"
              icon="trash"
              as="a"
              onClick={() => setDeleteConfirmOpen(true)}
              content="Delete List"
            />
            <Confirm
              header={`Delete "${list.name}"`}
              content={`This will delete your list "${list.name}" and return to your "Favorites" list. Are you sure?`}
              open={deleteConfirmOpen}
              onCancel={() => setDeleteConfirmOpen(false)}
              onConfirm={() => deleteList({ variables: { id: list.id } })}
            />
          </Form.Group>
          {error && (
            <Message
              error
              content={error.message}
            />
          )}
        </Form>
      )}

    </div>
  );
};

export default ListName;
