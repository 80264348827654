import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  Modal, Container, Rating, Button,
  Header, Form,
} from 'semantic-ui-react';
import { REVIEW_MEETING } from '../../queries/meeting';

const ReviewModal = ({ meeting, children }) => {
  const RATING_TEXTS = ['', 'Awful, not what I expected at all',
    'Poor, pretty disappointed', 'Average, could be better',
    'Good, what I expected', 'Amazing, above expectations!'];
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [open, setOpen] = useState(false);

  const [reviewMeeting] = useMutation(REVIEW_MEETING);

  const onSubmit = () => {
    reviewMeeting({
      variables: {
        userId: meeting.user.id,
        projectId: meeting.project.id,
        job: {
          expert_rating: rating,
          expert_comment: comment,
        },
      },
    });
    setOpen(false);
  };

  return (
    <Modal
      closeIcon
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      trigger={children}
      id="review-modal"
    >
      <Modal.Header>Leave a Review</Modal.Header>
      <Modal.Content>
        <Header>{`How would you rate your experience with ${meeting.user.fullName}?`}</Header>
        <Container textAlign="center">
          <Header>{RATING_TEXTS[rating]}</Header>
          <Rating
            icon="star"
            rating={rating}
            maxRating={5}
            onRate={(e, data) => setRating(data.rating)}
          />
        </Container>
        <Form>
          <Form.TextArea
            label={`Please briefly share your experience with ${meeting.user.fullName}`}
            value={comment}
            onInput={(e, { value }) => setComment(value)}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button className="basic-btn" onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button className="primary-btn" onClick={onSubmit}>
          Submit review
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ReviewModal;
