import React from 'react';
import { GridColumn, Form, GridRow } from 'semantic-ui-react';

const ShortAnswer = () => (
  <GridRow className="answer-choices short-answer">
    <GridColumn width={15}>
      <Form.Input placeholder="Short answer" readOnly />

    </GridColumn>
  </GridRow>
);

export default ShortAnswer;
