import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { SEARCH_EXPERTS } from '../queries/expert';
import Loading from '../components/Loading';
import MixpanelTracker from '../utils/MixpanelTracker';

const FILTER_CATEGORIES = ['country', 'title', 'organization.name',
  'organization.type', 'medical_specialties', 'technology_tags'];
const EXCLUSION_CATEGORIES = ['projects'];

const SearchContext = React.createContext({
  searchResult: null,
  query: '',
  appliedFilters: [],
  appliedExclusions: [],
  page: 0,
  updatePaginate: null,
  clearFilters: null,
  toggleFilter: null,
});
export const SearchProvider = ({ children }) => {
  const history = useHistory();
  const params = new URLSearchParams(useLocation().search);
  const appliedFilters = FILTER_CATEGORIES
    .map((cat) => ({ categoryId: cat, values: params.getAll(cat) }))
    .filter((filter) => (filter.values.length > 0));
  const appliedExclusions = EXCLUSION_CATEGORIES
    .map((cat) => ({ categoryId: cat, value: params.getAll(cat) }))
    .filter((xcl) => (xcl.value.length > 0));
  const query = params.get('query');
  const page = params.get('page') ? parseInt(params.get('page'), 10) : 1;

  const { loading, data } = useQuery(SEARCH_EXPERTS, {
    variables: {
      query: encodeURIComponent(query),
      filters: appliedFilters,
      exclusions: appliedExclusions,
      page,
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ search }) => {
      MixpanelTracker.trackSearch(query, search.total);
    },
  });

  if (loading) return <Loading />;

  const paginate = (newPage) => {
    params.set('page', newPage);
    history.push({ pathname: '/search', search: `?${params.toString()}` });
  };

  const clearFilters = () => {
    history.push({ pathname: '/search', search: `?query=${query}` });
  };

  const toggleFilter = (category, filter) => {
    const filtersInCategory = params.getAll(category);
    const filterExists = filtersInCategory.includes(filter);
    // add filter
    if (!filterExists) {
      params.append(category, filter);
    } else {
      // remove filter
      const filtersRemain = filtersInCategory.filter((f) => f !== filter);
      params.delete(category);
      filtersRemain.forEach((f) => params.append(category, f));
    }
    paginate(1);
  };

  return (
    <SearchContext.Provider value={{
      searchResult: data.search,
      query,
      appliedFilters,
      appliedExclusions,
      page,
      paginate,
      clearFilters,
      toggleFilter,
    }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export default SearchContext;
