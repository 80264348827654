import React from 'react';
import { Button } from 'semantic-ui-react';

const SendInviteButton = ({ loading, disabled, onClick }) => (
  <div>
    <div className="tos">
      {'By sending this invite, you agree to Dexter '}
      <a href="https://www.dexterexperts.com/terms-of-service" target="_blank" rel="noreferrer">terms of service</a>
      {' and '}
      <a href="https://www.dexterexperts.com/privacy-policy" target="_blank" rel="noreferrer">privacy policy</a>
    </div>
    <div>
      <Button
        className="primary-btn"
        loading={loading}
        disabled={disabled}
        onClick={onClick}
      >
        I agree, send invite
      </Button>
    </div>
  </div>
);

export default SendInviteButton;
