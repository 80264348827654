import React from 'react';
import { Table, Label, Segment } from 'semantic-ui-react';

const Members = ({ team }) => (
  <Segment id="members">
    <Table stackable basic="very" fixed>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Email</Table.HeaderCell>
          <Table.HeaderCell>Role</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {team.members.map((member) => (
          <Table.Row key={member.userId}>
            <Table.Cell>{member.user.email}</Table.Cell>
            <Table.Cell>
              <Label>{member.role}</Label>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  </Segment>
);

export default Members;
