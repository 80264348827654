import React from 'react';
import { Link } from 'react-router-dom';
import {
  Card, Statistic, Icon, List,
  Label, Progress, Header,
} from 'semantic-ui-react';
import momentTZ from 'moment-timezone';
import CompleteProjectButton from './CompleteProjectButton';
import DeleteProjectButton from './DeleteProjectButton';

const ProjectCard = ({ user, project }) => {
  const renderProjectStats = () => {
    if (project.status === 'Completed') {
      return (
        <div className="complete-stats">
          <Progress percent={100} success size="small" />
          <div className="stats">
            <Icon name="check circle" />
            {`${project.numCompleted} completed`}
          </div>
        </div>
      );
    }
    return (
      <Statistic.Group size="tiny" widths={3}>
        {
          project.type === 'Interview' && (
            <Statistic>
              <Statistic.Value>{project.numUpcomingMeetings}</Statistic.Value>
              <Statistic.Label>Scheduled</Statistic.Label>
            </Statistic>
          )
        }
        <Statistic>
          <Statistic.Value>{project.numCompleted}</Statistic.Value>
          <Statistic.Label>Completed</Statistic.Label>
        </Statistic>
        <Statistic>
          <Statistic.Value>{project.numPendingInvites}</Statistic.Value>
          <Statistic.Label>Pending</Statistic.Label>
        </Statistic>
      </Statistic.Group>
    );
  };

  const renderStatus = () => {
    let status;
    if (project.qualtricsSurvey && project.qualtricsSurvey.status === 'New') {
      status = 'Unpublished';
    } else {
      status = project.status === 'Completed' ? 'Completed' : 'Active';
    }
    return (
      <span className={`${status}-status status`}>
        {status}
      </span>
    );
  };

  return (
    <Card fluid>
      <Card.Content>
        {renderStatus()}
        <Card.Meta>
          Last updated
          {' '}
          {momentTZ.tz(project.updatedAt, user.timeZone).format('MM/DD/YY z')}
        </Card.Meta>
        <Link to={`/projects/${project.id}`}>
          <Card.Header>
            <Header>{project.title}</Header>
          </Card.Header>
          <Card.Meta>
            {project.owners.map((owner) => owner.email).join(', ')}
          </Card.Meta>
          <Card.Meta>
            <List horizontal>
              {project.keywords.map((tag) => (
                <List.Item key={tag}>
                  <Label>{tag}</Label>
                </List.Item>
              ))}
            </List>
          </Card.Meta>
        </Link>
        <Card.Description>
          {renderProjectStats()}
        </Card.Description>
        <DeleteProjectButton project={project} />
        <CompleteProjectButton project={project} />
      </Card.Content>
    </Card>
  );
};

export default ProjectCard;
