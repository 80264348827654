import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Loader } from 'semantic-ui-react';
import CenterizedLayout from '../layouts/CenterizedLayout';
import ProjectForm from '../components/Projects/ProjectForm';
import { QUESTIONS_DEFAULT } from '../components/Projects/ProjectForm/QualificationQuestions';
import DataContext from '../contexts/DataContext';
import MessageContext from '../contexts/MessageContext';
import { CREATE_PROJECT } from '../queries/project';

const PROJECT_DEFAULT = {
  type: '',
  title: '',
  description: '',
  keywords: [],
  targetN: 1,
  qualificationQuestions: QUESTIONS_DEFAULT,
};

const ProjectNew = ({ onCreate, onCancel }) => {
  const history = useHistory();
  const { user, managedProjectsRefetch } = useContext(DataContext);
  const { setCreatedProject } = useContext(MessageContext);

  const [createProject, { loading }] = useMutation(CREATE_PROJECT, {
    onCompleted: (data) => {
      setCreatedProject(data.createProject);
      managedProjectsRefetch();
      onCreate?.();
    },
  });

  const handleSubmit = (newProject) => {
    createProject({
      variables: {
        input: {
          ...newProject,
          team: user.membership[0].teamId,
          status: 'Draft',
          service_type: 'Self-Service',
          start_date: new Date(),
          end_date: new Date(new Date().setMonth(new Date().getMonth() + 1)),
        },
      },
    });
  };

  return (
    <CenterizedLayout
      title="Create new project"
      description="Enter the project details below.
        The information you provide here will be shared with the experts invited to the project."
    >
      <div id="create-project">
        <Loader active={loading} content="loading" />
        <ProjectForm
          project={PROJECT_DEFAULT}
          loading={loading}
          submit={handleSubmit}
          onCancel={() => {
            if (onCancel) onCancel();
            else history.push('/projects');
          }}
        />
      </div>
    </CenterizedLayout>
  );
};

export default ProjectNew;
