import React from 'react';
import { Grid, Segment, Header } from 'semantic-ui-react';

const CenterizedLayout = ({ title, description, children }) => (
  <Grid
    container
    stackable
    className="centerized-layout"
  >
    <Grid.Column>
      <Grid.Row className="heading">
        <Segment>
          <Header as="h2">{title}</Header>
          <p>{description}</p>
        </Segment>
      </Grid.Row>
      <Grid.Row className="content">{children}</Grid.Row>
    </Grid.Column>
  </Grid>
);

export default CenterizedLayout;
