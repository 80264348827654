import React from 'react';
import {
  GridRow, GridColumn, FormGroup, Form,
} from 'semantic-ui-react';

const Knockout = () => (
  <GridRow className="answer-choices">
    <GridColumn width={4}>
      <FormGroup grouped>
        <Form.Radio
          label="Yes"
          value="yes"
          readOnly
          style={{ cursor: 'none' }}
        />
        <Form.Radio
          label="No (Disqualify)"
          value="no"
          readOnly
          style={{ cursor: 'none' }}
        />

      </FormGroup>
    </GridColumn>
  </GridRow>
);

export default Knockout;
