import React from 'react';
import { Image } from 'semantic-ui-react';
import FLAGS from '../../constants/flags.json';

const ProfilePicture = ({ expert }) => {
  const getFlag = (country) => {
    const findIndex = FLAGS.map((obj) => obj.full_country).indexOf(country);
    return findIndex !== -1 ? FLAGS[findIndex].file_name : '';
  };

  return (
    <div className="expert-profile-pic">
      <Image
        size="tiny"
        src={expert.profile_picture || `${process.env.REACT_APP_AWS_S3_URL}/fake_avatar.png`}
        className="expert-avatar"
      />
      <img
        src={`${process.env.REACT_APP_AWS_S3_URL}/flags/${getFlag(expert.country)}`}
        className="country-flag"
        alt="country flag"
      />
    </div>
  );
};

export default ProfilePicture;
