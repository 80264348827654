import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { Header, Card, Grid } from 'semantic-ui-react';
import momentTZ from 'moment-timezone';
import { LIST } from '../../queries/list';
import ListName from './ListName';
import ShareListButton from './ShareListButton';
import FollowListButton from './FollowListButton';
import InviteAllButton from './InviteAllButton';
import ExpertCard from '../Experts/ExpertCard';
import Loading from '../Loading';
import DataContext from '../../contexts/DataContext';

const ListExperts = ({ id }) => {
  const { user } = useContext(DataContext);
  const { loading, data, error } = useQuery(LIST, {
    variables: { id },
  });

  if (loading) return (<Loading />);
  if (error) return (<Header>List is not available</Header>);

  const { list } = data;
  const timeFormat = 'MM/DD/YYYY';
  const updatedAt = momentTZ.tz(list.updatedAt, user.timeZone).format(timeFormat);

  return (
    <div id="list-experts">
      <Grid stackable>
        <Grid.Row columns={2}>
          <Grid.Column>
            <ListName list={data.list} />
            <Header.Subheader>
              {list.user.id !== user.id && `By ${list.user.fullName} of ${list.user.membership[0].teamName} - `}
              {`Updated ${updatedAt}`}
            </Header.Subheader>
          </Grid.Column>
          <Grid.Column textAlign="right">
            {list.name !== 'Favorites' && user.id === list.user.id && (
              <ShareListButton list={data.list} />
            )}
            {user.id !== list.user.id && (
              <FollowListButton list={data.list} />
            )}
            <InviteAllButton list={data.list} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Card.Group itemsPerRow={3} stackable>
        {list.experts.map((e) => <ExpertCard key={e.id} expert={e} />)}
      </Card.Group>
    </div>
  );
};

export default ListExperts;
