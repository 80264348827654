import { gql } from '@apollo/client';

export const MEETING_BASIC_INFO = `
  id
  user {
    id
    email
    fullName
    timeZone
  }
  interviewers {
    id
    email
    fullName
  }
  project {
    id
    owners {
      id
    }
    title
    description
    type
    team {
      id
    }
    preInterviewLink
  }
  expert {
    id
    title
    organization {
      name
    }
  }
  qualificationResponses {
    question
    response
  }
  invitedAt
  completedAt
  meetingAt
  meetingLink
  expert_rating
  expert_comment
  overall_status
`;

export const MEETING = gql`
  query Job($id: String!) {
    job(id: $id) {
      ${MEETING_BASIC_INFO}
      meetingInvite
    }
  }
`;

export const MEETING_INVITE = gql`
  query Job($id: String!) {
    job(id: $id) {
      id
      meetingInvite
    }
  }
`;

export const MEETING_REPORT = gql`
  query Job($id: String!) {
    job(id: $id) {
      id
      meetingReport {
        status
        participants {
          name
          email
          joinedAt
          leftAt
          numMinutes
        }
      }
    }
  }
`;

export const REVIEW_MEETING = gql`
  mutation UpdateJob($userId: ID!, $projectId: ID!, $job: JobInput!) {
    updateJob(userId: $userId, projectId: $projectId, job: $job) {
      id
      expert_rating
      expert_comment
    }
  }
`;

export const RESCHEDULE_MEETING = gql`
  mutation RescheduleMeeting($jobId: ID!) {
    rescheduleMeeting(jobId: $jobId) {
      id
    }
  }
`;

export const CANCEL_JOB = gql`
  mutation CancelJob($jobId: ID!, $reason: String!) {
    cancelJob(jobId: $jobId, reason: $reason) {
      id
    }
  }
`;

export default MEETING;
