import React, { useContext } from 'react';
import { Grid, Header } from 'semantic-ui-react';
import DataContext from '../contexts/DataContext';
import ProfileStats from '../components/Home/ProfileStats';
import MeetingTab from '../components/Home/MeetingTab';

const Home = () => {
  const { user } = useContext(DataContext);
  return (
    <Grid
      container
      stackable
      doubling
      id="home"
    >
      <Grid.Row>
        <Header className="title">{`Welcome back, ${user.fullName || user.email}`}</Header>
      </Grid.Row>
      <Grid.Row>
        <ProfileStats />
      </Grid.Row>
      <Grid.Row>
        <MeetingTab />
      </Grid.Row>
    </Grid>
  );
};

export default Home;
