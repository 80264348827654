import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Modal } from 'semantic-ui-react';
import { INVITE_EXPERT_TO_PROJECT } from '../../../queries/job';
import InsufficientCreditMessage from '../../InviteModal/InsufficientCreditMessage';
import SendInviteButton from '../../InviteModal/SendInviteButton';

const ReInviteExpert = ({ job, children }) => {
  const [open, setOpen] = useState(false);
  const [inviteExpert, { loading, error }] = useMutation(INVITE_EXPERT_TO_PROJECT);
  return (
    <Modal
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      trigger={children}
      closeIcon
      size="small"
      id="request-modal"
    >
      <Modal.Header>
        {`Re-invite ${job.user.fullName} to ${job.project.title}`}
      </Modal.Header>
      <Modal.Content>
        {error && <InsufficientCreditMessage />}
        <p>
          {`Are you sure to re-invite ${job.user.fullName}? This will send out an invitation email to this expert again.`}
        </p>
      </Modal.Content>
      <Modal.Actions>
        <SendInviteButton
          loading={loading}
          disabled={loading}
          onClick={() => inviteExpert({
            variables: { userId: job.user.id, projectId: job.project.id },
          })}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default ReInviteExpert;
