import React, { useContext } from 'react';
import {
  Switch, Route, useParams, useHistory,
} from 'react-router-dom';
import { useMutation } from '@apollo/client';
import CenterizedLayout from '../../layouts/CenterizedLayout';
import TourLinks from '../../components/Tours/TourLinks';
import Welcome from './Welcome';
import Introduction from './Introduction';
import Availability from '../../components/Settings/Availability';
import AllSet from './AllSet';
import DataContext from '../../contexts/DataContext';
import PROFILE, { UPDATE_SETTING } from '../../queries/profile';

const Tours = () => {
  const history = useHistory();
  const { page } = useParams();
  const { user } = useContext(DataContext);

  const [updateSetting] = useMutation(UPDATE_SETTING, {
    variables: { name: 'clientOnboarded', value: 'true' },
    update(cache, { data }) {
      cache.writeQuery({
        query: PROFILE,
        data: { me: data.updateSetting },
      });
    },
  });

  const onboardedData = user.settings.filter((s) => s.name === 'clientOnboarded')[0];
  const isOnboarded = onboardedData.value === 'true';

  const onExitTour = (redirect) => {
    if (!isOnboarded) {
      updateSetting();
    }
    history.push(redirect);
  };

  const TOURS_HEADINGS = {
    welcome: {
      title: 'Welcome to Dexter!',
      description: 'Dexter is a go-to marketplace for booking meetings with experts on demand',
      nextButton: 'Show me the tour >',
      prevButton: 'Skip the tour. Go to home',
      onNext: () => history.push('/tours/introduction'),
      onPrev: () => onExitTour('/home'),
    },
    availability: {
      title: 'Your Availability',
      description: `Tell us your standard work hours.
        Your Dexter meetings will only take place during these hours.
        Don't worry, you can always change them or set exceptions later.`,
      nextButton: 'I am done setting my availability >',
      prevButton: '<',
      onNext: () => history.push('/tours/allset'),
      onPrev: () => history.push('/tours/introduction'),
    },
    introduction: {
      title: '3-Easy Steps to Booking Expert Interviews',
      description: `Dexter provides a seamless user experience for finding and
        booking meetings with the most relevant experts for your unique questions`,
      nextButton: 'Got it! Take me to next step >',
      prevButton: '<',
      onNext: () => history.push('/tours/availability'),
      onPrev: () => history.push('/tours/welcome'),
    },
    allset: {
      title: 'That\'s It For Now!',
      description: `You are ready to get started!
        Check out one of our popular expert categories below or Go to Home`,
      nextButton: 'Go to Home',
      prevButton: '<',
      onNext: () => onExitTour('/home'),
      onPrev: () => history.push('/tours/availability'),
      primary: true,
    },
  };

  const {
    title, description, nextButton, prevButton, onNext, onPrev, primary,
  } = TOURS_HEADINGS[page];

  return (
    <CenterizedLayout title={title} description={description}>
      <div>
        <Switch>
          <Route path="/tours/welcome" component={Welcome} />
          <Route path="/tours/introduction" component={Introduction} />
          <Route path="/tours/availability" render={() => <Availability user={user} />} />
          <Route path="/tours/allset" render={() => <AllSet onExitTour={onExitTour} />} />
        </Switch>
        <TourLinks
          nextButton={nextButton}
          prevButton={prevButton}
          onNext={onNext}
          onPrev={onPrev}
          primary={primary}
        />
      </div>
    </CenterizedLayout>
  );
};

export default Tours;
