import React, { useState, useContext } from 'react';
import { useMutation } from '@apollo/client';
import {
  Segment, Button, Header, Divider, List,
  Modal,
} from 'semantic-ui-react';
import MemberSelections from './MemberSelections';
import { UPDATE_PROJECT } from '../../../queries/project';
import DataContext from '../../../contexts/DataContext';

const Owners = ({ project }) => {
  const { user } = useContext(DataContext);
  const [editing, setEditing] = useState(false);
  const [ownerIds, setOwnerIds] = useState(project.owners.map((o) => o.id));

  const [updateProject, { loading }] = useMutation(UPDATE_PROJECT, {
    onCompleted: () => setEditing(false),
  });

  return (
    <Segment className="project-form">
      <strong className="project-title">Project Owners</strong>
      <Divider />
      <Header>Team members who have full access to the project.</Header>
      <List>
        {project.owners.map((owner) => (
          <List.Item key={owner.id}>
            {owner.email}
          </List.Item>
        ))}
      </List>
      {project.type === 'Interview' && project.owners.length > 1 && (
        <Header as="h5">Please check the project’s scheduling settings if you have multiple owners.</Header>
      )}
      {project.status !== 'Completed' && (
        <Modal
          closeIcon
          open={editing}
          onOpen={() => setEditing(true)}
          onClose={() => setEditing(false)}
          trigger={<Button className="basic-btn" size="tiny" content="Edit" />}
        >
          <Modal.Header>Project Owners</Modal.Header>
          <Modal.Content>
            <Header>Select team members who have full access to the project.</Header>
            <MemberSelections
              selections={ownerIds}
              setSelections={setOwnerIds}
              exclusions={[user.id]}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button className="basic-btn" onClick={() => setEditing(false)}>Cancel</Button>
            <Button
              className="primary-btn"
              loading={loading}
              disabled={loading}
              onClick={() => updateProject({
                variables: {
                  id: project.id,
                  input: { owners: ownerIds },
                },
              })}
            >
              Save
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </Segment>
  );
};

export default Owners;
