import React, { useState, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { Popup, Icon, Loader } from 'semantic-ui-react';
import ActionConfirm from './ActionConfirm';
import { DELETE_PROJECT } from '../../queries/project';
import DataContext from '../../contexts/DataContext';
import MessageContext from '../../contexts/MessageContext';

const DeleteProjectButton = ({ project }) => {
  const { managedProjectsRefetch } = useContext(DataContext);
  const { setSuccessPopup } = useContext(MessageContext);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [deleteProject, { loading }] = useMutation(DELETE_PROJECT, {
    onCompleted: () => {
      managedProjectsRefetch();
      setSuccessPopup({
        title: `${project.title} has been deleted`,
        content: 'The project has been deleted and removed from your dashboard',
      });
    },
  });

  if (!project.deletable) return <div />;

  return (
    <>
      {confirmOpen && (
        <ActionConfirm
          projectTitle={project.title}
          onCancel={() => setConfirmOpen(false)}
          onAction={() => deleteProject({
            variables: { id: project.id },
            update: (cache) => {
              const normalizedId = cache.identify({ id: project.id, __typename: 'Project' });
              cache.evict({ id: normalizedId });
              cache.gc();
            },
          })}
          type="delete"
        />
      )}
      {loading ? (
        <Loader active inline />
      ) : (
        <Popup
          content="Delete this project"
          trigger={(
            <Icon
              name="trash alternate"
              size="large"
              className="remove-icon"
              onClick={() => setConfirmOpen(true)}
            />
        )}
        />
      )}
    </>
  );
};

export default DeleteProjectButton;
