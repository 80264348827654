import React from 'react';
import {
  GridRow, GridColumn, Form, Icon,
  Divider,
  Grid,
} from 'semantic-ui-react';
import Knockout from './Knockout';
import ShortAnswer from './ShortAnswer';
import Choices from './Choices';

const QUESTION_TYPES = [
  {
    key: 1, text: 'Multiple Choice', value: 'multipleChoice', type: 'Radio',
  }, {
    key: 2, text: 'Checkboxes', value: 'checkbox', type: 'Checkbox',
  }, {
    key: 3, text: 'Short Answer', value: 'shortAnswer', type: 'TextArea',
  }];

const KNOCKOUT = [
  { key: 1, text: 'Knockout', value: 'knockout' },
];

const QuestionForm = ({
  updateProject, qualificationQuestions, isKnockout,
}) => {
  const questionsToDisplay = qualificationQuestions.filter((q) => (isKnockout
    ? q.questionType === 'knockout'
    : q.questionType !== 'knockout'));
  const updateQuestion = (id, input) => {
    const tempQualificationQuestions = qualificationQuestions.slice();
    const index = tempQualificationQuestions.findIndex((q) => q.id === id);
    tempQualificationQuestions[index] = { ...tempQualificationQuestions[index], ...input };
    updateProject({ qualificationQuestions: tempQualificationQuestions });
  };

  const deleteQuestion = (id) => {
    updateProject({
      qualificationQuestions: qualificationQuestions.filter((q) => q.id !== id),
    });
  };
  return questionsToDisplay.map((q, index) => (
    <Grid key={q.id}>
      <GridRow>
        <GridColumn width={13}>
          <Form.Input
            placeholder="Enter a question"
            value={q.question}
            onChange={(e, { value }) => updateQuestion(q.id, {
              question: value,
            })}
          >
            {(index !== 0 || q.question !== '') && (
            <Icon
              name="minus circle"
              size="large"
              className="remove-question-btn"
              onClick={() => deleteQuestion(q.id)}
            />
            )}
            <input />
          </Form.Input>
        </GridColumn>
        <GridColumn width={3}>
          <Form.Dropdown
            options={q.questionType === 'knockout' ? KNOCKOUT : QUESTION_TYPES}
            value={q.questionType}
            disabled={q.questionType === 'knockout'}
            onChange={(e, { value }) => {
              const updatedData = {
                questionType: value,
                ...(value !== 'shortAnswer'
                  && (!q.answerChoices || q.answerChoices.length === 0)
                  && { answerChoices: new Array(3).fill('') }),
              };
              updateQuestion(q.id, updatedData);
            }}
          />
        </GridColumn>
      </GridRow>
      {q.questionType === 'knockout' && (<Knockout />)}
      {q.questionType === 'shortAnswer' && (<ShortAnswer />)}
      {(q.questionType === 'multipleChoice' || q.questionType === 'checkbox') && (
      <Choices
        question={q}
        updateQuestion={updateQuestion}
      />
      )}
      <Divider />
    </Grid>
  ));
};

export default QuestionForm;
