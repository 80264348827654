import React from 'react';
import {
  Segment, Image, List, Header,
} from 'semantic-ui-react';

const Introduction = () => (
  <Segment id="introduction">
    <div className="description">
      <Header>Booking expert interviews is as easy as:</Header>
      <List ordered>
        <List.Item>Set your default availability for Dexter meetings</List.Item>
        <List.Item>Request a call with experts of your choice</List.Item>
        <List.Item>Call the conference line provided at the scheduled time</List.Item>
      </List>
    </div>
    <Image
      centered
      size="big"
      src={`${process.env.REACT_APP_AWS_S3_URL}/introduction.png`}
    />
  </Segment>
);

export default Introduction;
