import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';

const CreateProjectButton = () => {
  const history = useHistory();
  return (
    <Button
      icon
      labelPosition="left"
      className="primary-btn"
      onClick={() => history.push('/projects/new')}
    >
      <Icon name="plus" />
      Create New Project
    </Button>
  );
};

export default CreateProjectButton;
