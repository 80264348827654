import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { List } from 'semantic-ui-react';
import DataContext from '../../contexts/DataContext';

const NavLinks = () => {
  const { myLists } = useContext(DataContext);

  return (
    <List horizontal className="nav-link">
      <List.Item>
        <NavLink to="/home" exact>Home</NavLink>
      </List.Item>
      <List.Item>
        <NavLink to="/projects">Projects</NavLink>
      </List.Item>
      <List.Item>
        <NavLink to={`/lists/${myLists[0].id}`}>Lists</NavLink>
      </List.Item>
      <List.Item>
        <NavLink to="/schedule">My Schedule</NavLink>
      </List.Item>
    </List>
  );
};

export default NavLinks;
