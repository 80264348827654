import React, { useState, useContext } from 'react';
import { useMutation } from '@apollo/client';
import {
  Modal, Checkbox, Table, Divider,
} from 'semantic-ui-react';
import ProjectNewButton from './ProjectNewButton';
import SendInviteButton from './SendInviteButton';
import InsufficientCreditMessage from './InsufficientCreditMessage';
import { INVITE_LIST_TO_PROJECT } from '../../queries/list';
import DataContext from '../../contexts/DataContext';
import MessageContext from '../../contexts/MessageContext';

const InviteListModal = ({ list, children }) => {
  const { managedProjects, team } = useContext(DataContext);
  const { setSuccessPopup } = useContext(MessageContext);

  const [open, setOpen] = useState(false);
  const [projectIds, setProjectIds] = useState([]);
  const [numInvites, setNumInvites] = useState(0);
  const [error, setError] = useState(false);

  const [inviteList, { loading }] = useMutation(INVITE_LIST_TO_PROJECT);

  const onCheckboxClick = (checked, project, numToInvite) => {
    let newProjectIds = projectIds;
    let newNumToInvite = numToInvite;
    if (checked) {
      newProjectIds = projectIds.concat(project.id);
      newNumToInvite = numInvites + numToInvite;
    } else {
      newProjectIds = projectIds.filter((pId) => pId !== project.id);
      newNumToInvite = numInvites - numToInvite;
    }
    setProjectIds(newProjectIds);
    setNumInvites(newNumToInvite);
    setError(team.availableInvites < newNumToInvite);
  };

  const onRequest = () => {
    const promises = [];
    projectIds.forEach(
      (projectId) => promises.push(inviteList({ variables: { listId: list.id, projectId } })),
    );
    Promise.all(promises).then(() => {
      setSuccessPopup({
        title: `Your invites have been sent to all experts from list ${list.name}`,
        content: `
          Great news! Your invites are on their way.
          Please check your Dexter dashboard for status updates.
        `,
        onClose: () => {
          setProjectIds([]);
          setNumInvites(0);
          setOpen(false);
        },
      });
    });
  };
  return (
    <Modal
      closeIcon
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      trigger={children}
      size="small"
      id="request-modal"
    >
      <Modal.Header>
        {`Invite all experts from the list (${list.name}) to...`}
        {projectIds.length > 0 && <span className="meta">{`${projectIds.length} project(s) selected`}</span>}
      </Modal.Header>
      <Modal.Content>
        {error && <InsufficientCreditMessage />}
        <Table celled structured>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell rowSpan="2" />
              <Table.HeaderCell rowSpan="2">Project titles</Table.HeaderCell>
              <Table.HeaderCell colSpan="2">Experts from this list</Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Invited</Table.HeaderCell>
              <Table.HeaderCell>Not invited</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {managedProjects.filter((p) => p.displayForInviteModal).map((p) => {
              const projectExpertIds = p.jobs.map((j) => j.user.id);
              const numInvited = list.experts.filter(
                (e) => projectExpertIds.includes(e.id),
              ).length;
              const numToInvite = list.experts.filter(
                (e) => !projectExpertIds.includes(e.id),
              ).length;
              const isInvited = numToInvite === 0;
              return (
                <Table.Row key={p.id} disabled={isInvited || p.isUnpublishedSurvey}>
                  <Table.Cell>
                    <Checkbox
                      defaultChecked={isInvited || projectIds.includes(p.id)}
                      disabled={isInvited || p.isUnpublishedSurvey}
                      onClick={
                      (e, { checked }) => onCheckboxClick(checked, p, numToInvite)
                    }
                    />
                  </Table.Cell>
                  <Table.Cell>
                    {`${p.isUnpublishedSurvey ? '(Unpublished survey) ' : ''}${p.title}`}
                  </Table.Cell>
                  <Table.Cell>{numInvited}</Table.Cell>
                  <Table.Cell>{numToInvite}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        <ProjectNewButton />
        <Divider />
        <span className="meta">{`You have ${team.availableInvites} invites left!`}</span>
      </Modal.Content>
      <Modal.Actions>
        <SendInviteButton
          loading={loading}
          disabled={team.availableInvites < numInvites || projectIds.length === 0 || loading}
          onClick={onRequest}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default InviteListModal;
