import React from 'react';
import DataContext from '../contexts/DataContext';

class Security {
  static isInterviewer(meeting) {
    const { user } = React.useContext(DataContext);
    return meeting.interviewers.some((i) => i.id === user.id);
  }

  static isTeamMember(team) {
    const { user } = React.useContext(DataContext);
    return user.membership.some((m) => m.teamId === team.id);
  }

  static isProjectOwner(project) {
    const { user } = React.useContext(DataContext);
    return project.owners.some((owner) => owner.id === user.id);
  }
}

export default Security;
