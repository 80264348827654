import React from 'react';
import { Message } from 'semantic-ui-react';

const InsufficientCreditMessage = () => (
  <Message negative>
    <Message.Header>Request could not be sent due to insufficient credits</Message.Header>
    <p>
      Please check to make sure you have enough credits to send invitations.
      Note that there may be credits on hold if
      there are scheduled meetings or pending invitations sent within the past 24 hours.
      To proceed, you may need to add more credits or come back after some time to check
      if credits from expired invitations are deposited back to your account.
    </p>
  </Message>
);

export default InsufficientCreditMessage;
