import React, { useState, useContext } from 'react';
import { useMutation } from '@apollo/client';
import {
  ButtonGroup, Button, Dropdown, DropdownMenu, DropdownItem, DropdownDivider,
} from 'semantic-ui-react';
import DataContext from '../../contexts/DataContext';
import { ADD_EXPERT_TO_LIST, DELETE_EXPERT_FROM_LIST } from '../../queries/list';
import ListNew from './ListNew';

const ExpertListsButton = ({ expert }) => {
  const [loading, setLoading] = useState(false);
  const { myLists } = useContext(DataContext);
  const [addExpertToList, { loading: addLoading }] = useMutation(ADD_EXPERT_TO_LIST);
  const [deleteExpertFromList, { loading: deleteLoading }] = useMutation(DELETE_EXPERT_FROM_LIST);

  // Array.sort modifies the variable and myLists is read only
  const currentList = [...myLists].sort((a, b) => b.updatedAt - a.updatedAt)[0];
  const addedToCurrentList = currentList.experts.some((e) => e.id === expert.id);
  const currentVariables = { listId: currentList.id, expertId: expert.id };
  const favoritesList = myLists.find((list) => list.name === 'Favorites');
  const otherLists = myLists.filter((list) => list.name !== 'Favorites').sort((a, b) => b.updatedAt - a.updatedAt);
  const allLists = [favoritesList, ...otherLists];

  const dropDownItems = allLists.map((list) => {
    const expertAdded = list.experts.some((e) => e.id === expert.id);
    const variables = { listId: list.id, expertId: expert.id };
    return (
      <DropdownItem
        key={list.id}
        icon={expertAdded ? 'check' : 'square outline'}
        text={list.name}
        value={list.id}
        onClick={() => (
          expertAdded
            ? deleteExpertFromList({ variables })
            : addExpertToList({ variables })
        )}
      />
    );
  });

  return (
    <ButtonGroup fluid className="expert-list-button">
      <Button
        icon={addedToCurrentList ? 'check' : 'plus'}
        content={`Save${addedToCurrentList ? 'd' : ''} to 
          ${currentList.name.substring(0, 15)}${currentList.name.length > 15 ? '..' : ''}`}
        className={addedToCurrentList ? 'basic-btn' : 'primary-btn'}
        loading={loading || addLoading || deleteLoading}
        onClick={() => (
          addedToCurrentList
            ? deleteExpertFromList({ variables: currentVariables })
            : addExpertToList({ variables: currentVariables })
        )}
      />
      <Dropdown
        className="button icon"
      >
        <DropdownMenu>
          {dropDownItems}
          <DropdownDivider />
          <ListNew expert={expert} setLoading={setLoading} />
        </DropdownMenu>
      </Dropdown>
    </ButtonGroup>
  );
};

export default ExpertListsButton;
