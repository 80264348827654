import React from 'react';
import { Grid, Header } from 'semantic-ui-react';
import BarChart from './BarChart';
import ProjectForecastHelper from '../../../utils/ProjectForecastHelper';

const ProjectResponseForecast = ({ timezone, jobs, daysFromNow }) => {
  const {
    numCompleted,
    numScheduled,
    numPredicted,
    numResponses,
  } = ProjectForecastHelper.getResponseForecast(jobs, daysFromNow, timezone);
  return (
    <Grid.Column>
      <Header>{`Total Forecasted Experts: ${numResponses}`}</Header>
      <div className="bar-chart-container">
        <BarChart data={{ numCompleted, numScheduled, numPredicted }} />
        <div className="axis-label">Expert Respondents</div>
      </div>
      <div className="note">
        * End-of-day project acceptance prediction based on your pending invites
      </div>
    </Grid.Column>
  );
};

export default ProjectResponseForecast;
