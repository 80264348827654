import React from 'react';
import { Rating } from 'semantic-ui-react';
import ReviewModal from './Meetings/ReviewModal';
import CustomLink from './CustomLink';
import Security from '../utils/Security';

const Review = ({ job }) => {
  if (job.overall_status === 'Scheduled') return null;
  return (
    <div>
      <Rating
        icon="star"
        rating={job.expert_rating || 0}
        maxRating={5}
        disabled
      />
      {Security.isProjectOwner(job.project) && !job.expert_rating && ['Completed', 'Paid'].includes(job.overall_status) && (
        <>
          <br />
          <ReviewModal meeting={job}>
            <CustomLink>
              Leave a review
            </CustomLink>
          </ReviewModal>
        </>
      )}
    </div>
  );
};

export default Review;
