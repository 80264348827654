import React, { useState, useEffect } from 'react';
import { Message, Icon } from 'semantic-ui-react';
import SuccessPopup from '../components/SuccessPopup';
import ErrorPopup from '../components/ErrorPopup';
import ProjectCreationSuccessPopup from '../components/Projects/ProjectCreationSuccessPopup';

const MessageContext = React.createContext({});
export const MessageProvider = ({ children }) => {
  const [successPopup, setSuccessPopup] = useState(null);
  const [errorPopup, setErrorPopup] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [createdProject, setCreatedProject] = useState(null);

  useEffect(() => {
    if (errorMsg || successMsg) {
      // When there's a message, move the view to the top where the message's located
      window.scrollTo(0, 0);
    }
  }, [errorMsg, successMsg]);

  return (
    <MessageContext.Provider value={{
      setErrorPopup, setSuccessPopup, setErrorMsg, setSuccessMsg, setCreatedProject,
    }}
    >
      {successPopup && (
        <SuccessPopup
          title={successPopup.title}
          content={successPopup.content}
          onClose={() => {
            if (successPopup.onClose) {
              successPopup.onClose();
            }
            setSuccessPopup(null);
          }}
        />
      )}
      {errorPopup && (
        <ErrorPopup
          title={errorPopup.title}
          header={errorPopup.header}
          content={errorPopup.content}
          onClose={() => {
            if (errorPopup.onClose) {
              errorPopup.onClose();
            }
            setErrorPopup(null);
          }}
        />
      )}
      {createdProject && (
        <ProjectCreationSuccessPopup
          project={createdProject}
          onClose={() => setCreatedProject(null)}
        />
      )}
      {errorMsg && (
        <Message negative onDismiss={() => setErrorMsg(null)}>
          <Icon name="info circle" size="large" />
          {errorMsg}
        </Message>
      )}
      {successMsg && (
        <Message positive onDismiss={() => setSuccessMsg(null)}>
          <Icon name="check circle" size="large" />
          {successMsg}
        </Message>
      )}
      {children}
    </MessageContext.Provider>
  );
};

export default MessageContext;
