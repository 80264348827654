import React, { useState } from 'react';
import {
  Button, Form, Input, Icon, Menu,
} from 'semantic-ui-react';

const ProjectFilters = ({ filters, updateFilter }) => {
  const [query, setQuery] = useState(filters.query);
  return (
    <Menu pointing vertical fluid size="huge">
      <Menu.Item>
        <Menu.Header>SHOW</Menu.Header>
        <Menu.Menu>
          <Menu.Item
            name="My projects"
            active={filters.myProjectsOnly}
            onClick={() => updateFilter({ myProjectsOnly: true })}
          />
          <Menu.Item
            name="All projects"
            active={!filters.myProjectsOnly}
            onClick={() => updateFilter({ myProjectsOnly: false })}
          />
        </Menu.Menu>
      </Menu.Item>
      <Menu.Item>
        <Menu.Header>STATUS</Menu.Header>
        <Menu.Menu>
          <Menu.Item
            name="Active"
            active={
              filters.status.length === 2 && filters.status.includes('Active', 'Draft')
            }
            onClick={() => updateFilter({ status: ['Active', 'Draft'] })}
          />
          <Menu.Item
            name="Completed"
            active={
              filters.status.length === 1 && filters.status[0] === 'Completed'
            }
            onClick={() => updateFilter({ status: ['Completed'] })}
          />
          <Menu.Item
            name="All"
            active={
              filters.status.length === 3 && filters.status.includes('Active', 'Draft', 'Compeletd')
            }
            onClick={() => updateFilter({ status: ['Active', 'Draft', 'Completed'] })}
          />
        </Menu.Menu>
      </Menu.Item>
      <Menu.Item>
        <Form onSubmit={() => updateFilter({ query })}>
          <Input
            fluid
            placeholder="Search projects by title"
            value={query}
            onChange={(e, { value }) => setQuery(value)}
            label={(<Button icon><Icon name="search" /></Button>)}
            labelPosition="right"
          />
        </Form>
      </Menu.Item>
    </Menu>
  );
};

export default ProjectFilters;
