import React from 'react';
import { Header, Form } from 'semantic-ui-react';

const ProjectCode = ({ settings, setSettings }) => (
  <div className="additional-setting last-additional-setting">
    <Header>
      Project identification code
      <Header.Subheader>
        An identification code is for your internal use.
        It can help you track and organize projects with your team
      </Header.Subheader>
    </Header>
    <Form.Input
      placeholder="EXAMPLE001"
      value={settings.projectCode}
      onChange={(e, { value }) => setSettings({ ...settings, projectCode: value })}
    />
  </div>
);

export default ProjectCode;
