import React from 'react';
import { Popup, Form } from 'semantic-ui-react';

const FormFieldWithPopup = ({
  popupContent, control: FormControl, label, ...rest
}) => (
  <Form.Field required={rest.required}>
    <Popup
      trigger={<label htmlFor="control-form">{label}</label>}
      content={popupContent}
    />
    <FormControl
      id="control-form"
      placeholder={rest.placeholder}
      value={rest.value}
      onChange={rest.onChange}
      // eslint-disable-next-line
      {...rest}
    />
  </Form.Field>
);

export default FormFieldWithPopup;
