import React from 'react';
import { Icon } from 'semantic-ui-react';
import { CSVLink } from 'react-csv';
import moment from 'moment';

const ExportExpertButton = ({ jobs }) => {
  const exportData = [
    // Headers
    [
      'Name',
      'Title',
      'Country',
      'Organization',
      'Organization Type',
      'Status',
      'Meeting At',
    ],
    ...jobs.map((job) => [
      job.user.fullName,
      job.expert.title,
      job.expert.country,
      job.expert.organization.name,
      job.expert.organization.type,
      job.overall_status,
      job.meetingAt ? moment(job.meetingAt).format('LLLL') : 'N/A',
    ]),
  ];
  return (
    <CSVLink
      className="ui icon labeled right floated basic small button"
      filename="experts.csv"
      data={exportData}
    >
      <Icon name="download" />
      Export data
    </CSVLink>
  );
};

export default ExportExpertButton;
