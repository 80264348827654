import React, { useContext } from 'react';
import {
  Switch, Route, useParams,
} from 'react-router-dom';
import CenterizedLayout from '../layouts/CenterizedLayout';
import Profile from '../components/Settings/Profile';
import Availability from '../components/Settings/Availability';
import Members from '../components/Settings/Members';
import Credits from '../components/Settings/Credits';
import DataContext from '../contexts/DataContext';
import SETTING_HEADINGS from '../constants/settingHeadings';

const Settings = () => {
  const { page } = useParams();
  const { user, team } = useContext(DataContext);
  const { title, description } = SETTING_HEADINGS[page];

  return (
    <CenterizedLayout title={title} description={description}>
      <Switch>
        <Route path="/settings/credits" render={() => <Credits user={user} team={team} />} />
        <Route path="/settings/members" render={() => <Members team={team} />} />
        <Route path="/settings/availability/:tab" render={() => <Availability user={user} />} />
        <Route path="/settings/availability" render={() => <Availability user={user} />} />
        <Route path="/settings/profile" render={() => <Profile user={user} />} />
      </Switch>
    </CenterizedLayout>
  );
};

export default Settings;
