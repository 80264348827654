import React, {
  useEffect, useRef, useState, useContext,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import {
  Button, Segment, Icon, Header, Grid, Confirm, Image, List, Message, Divider,
} from 'semantic-ui-react';
import ConfigureButton from './ConfigureButton';
import PROFILE from '../../../queries/profile';
import { UPDATE_EXTERNAL_CREDENTIAL, DELETE_EXTERNAL_ACCOUNT } from '../../../queries/externalAccount';
import MessageContext from '../../../contexts/MessageContext';

const ExternalAccounts = ({ user }) => {
  const ERROR_MSG = `
  There was an error connecting your calendar.
  Please try again & make sure to select all permission access terms. 
`;
  const history = useHistory();
  const { setSuccessMsg, setErrorMsg } = useContext(MessageContext);
  const clientRef = useRef();
  const [deleteAccount, setDeleteAccount] = useState(null);
  const [connecting, setConnecting] = useState(false);

  const [updateExternalCredential] = useMutation(UPDATE_EXTERNAL_CREDENTIAL);
  const [deleteExternalAccount] = useMutation(DELETE_EXTERNAL_ACCOUNT, {
    update(cache, { data }) {
      const profileData = cache.readQuery({ query: PROFILE });
      cache.modify({
        id: cache.identify(profileData?.me),
        fields: {
          externalAccounts(existingExternalAccountRefs, { readField }) {
            return existingExternalAccountRefs.filter(
              (externalAccountRef) => data.deleteExternalAccount.id !== readField('id', externalAccountRef),
            );
          },
        },
      });
    },
    onCompleted: () => {
      const { accountId } = deleteAccount;
      setSuccessMsg(`Your calendar (${accountId}) has been successfully disconnected`);
      setDeleteAccount(null);
    },
  });

  const signInWithGoogle = () => {
    clientRef.current.requestCode();
  };

  const signInWithOutlook = async () => {
    const authURL = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize';
    const clientId = process.env.REACT_APP_OUTLOOK_CLIENT_ID;
    const scope = 'User.Read Calendars.Read offline_access';
    const redirectUri = `${process.env.REACT_APP_BASE_URL}/settings/availability/external_calendars`;
    window.location.replace(`
      ${authURL}
      ?client_id=${clientId}
      &scope=${scope}
      &redirect_uri=${redirectUri}
      &response_type=code
      &prompt=select_account`);
  };

  const reconnectExternalCalendar = (account) => {
    if (account.provider === 'Google') {
      signInWithGoogle();
    }
    if (account.provider === 'Outlook') {
      signInWithOutlook();
    }
  };

  const handleCallbackResponse = (response) => {
    if (response.error || !response.scope.includes('https://www.googleapis.com/auth/calendar.readonly')) {
      setErrorMsg(ERROR_MSG);
      return;
    }
    updateExternalCredential({
      variables: { provider: 'Google', credential: response.code },
    });
  };

  useEffect(() => {
    // Connect Google Calendar
    const client = window.google.accounts.oauth2.initCodeClient({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      scope: 'email https://www.googleapis.com/auth/calendar.readonly',
      callback: handleCallbackResponse,
    });
    clientRef.current = client;
    // Connect Outlook Calendar
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (code) {
      setConnecting(true);
      updateExternalCredential({
        variables: { provider: 'Outlook', credential: code },
        onCompleted: () => {
          setConnecting(false);
          history.push(window.location.pathname);
        },
        onError: () => {
          setConnecting(false);
          setErrorMsg(ERROR_MSG);
        },
      });
    }
  }, []);

  const renderConnectionSegments = () => {
    if (user.externalAccounts.length === 0) {
      return (
        <p className="indented-alert">You haven&apos;t added any calendars yet.</p>
      );
    }

    const connectionSegments = user.externalAccounts.map((account) => (
      <div key={account.id} className="connection">
        <Grid stackable padded>
          <Grid.Row columns={3}>
            <Grid.Column width={1}>
              {account.provider === 'Google' ? (
                <Icon name="google" size="big" />
              ) : (
                <Icon name="microsoft" size="big" />
              )}
            </Grid.Column>
            <Grid.Column width={9}>
              <Header as="h4">
                {account.provider}
                <Header.Subheader>{account.accountId}</Header.Subheader>
              </Header>
              <Header as="h5">
                Check these calendar(s) for availability:
                <Header.Subheader>
                  <List bulleted>
                    {account.subscribedCalendars.length === 0 ? (
                      <List.Item>{`${account.accountId} (primary)`}</List.Item>
                    ) : (
                      <>
                        {account.subscribedCalendars.map((cal) => (
                          <List.Item key={cal.calendarId}>
                            {`${cal.name} ${cal.calendarId === 'primary' ? '(primary)' : ''}`}
                          </List.Item>
                        ))}
                      </>
                    )}
                  </List>
                </Header.Subheader>
              </Header>
            </Grid.Column>
            <Grid.Column width={6} textAlign="right">
              {account.status === 'error' ? (
                <Button
                  content="Reconnect"
                  size="small"
                  className="primary-btn"
                  onClick={() => reconnectExternalCalendar(account)}
                />
              ) : (
                <ConfigureButton
                  account={account}
                  reconnectExternalCalendar={reconnectExternalCalendar}
                />
              )}
              <Button
                size="small"
                className="basic-btn"
                onClick={() => setDeleteAccount(account)}
              >
                Disconnect
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {account.status === 'error' && (
          <Message negative>
            <Message.Header>Your external connection is expired</Message.Header>
            <p>Please try again by reconnecting your external account</p>
          </Message>
        )}
        <Divider />
      </div>
    ));
    if (connecting) {
      connectionSegments.push(<Segment key="loader" loading className="connection" />);
    }
    return connectionSegments;
  };

  return (
    <Segment className="small-box white-box" id="external-accounts">
      {deleteAccount && (
        <Confirm
          open
          closeIcon
          size="tiny"
          header="Disconnect external account"
          content="Are you sure you want to disconnect this account?"
          onCancel={() => setDeleteAccount(null)}
          onConfirm={() => deleteExternalAccount({ variables: { id: deleteAccount.id } })}
          cancelButton={<Button className="basic-btn">Cancel</Button>}
          confirmButton={<Button className="primary-btn">Yes</Button>}
        />
      )}
      <i>
        Link your external calendar(s) so that your availability is always up to date.
      </i>
      <Header>My External Calendars</Header>
      {renderConnectionSegments()}
      <Header>Add an External Calendar</Header>
      <Grid stackable columns={3} padded>
        <Grid.Column width={1} verticalAlign="middle">
          <Icon name="google" size="big" />
        </Grid.Column>
        <Grid.Column width={12} verticalAlign="middle">
          <Header as="h4">Google Calendar</Header>
        </Grid.Column>
        <Grid.Column width={3} textAlign="right">
          <Image
            src={`${process.env.REACT_APP_AWS_S3_URL}/btn_google_signin_dark.png`}
            className="span-link"
            onClick={signInWithGoogle}
          />
        </Grid.Column>
      </Grid>
      <Divider />
      <Grid stackable columns={3} padded>
        <Grid.Column width={1} verticalAlign="middle">
          <Icon name="microsoft" size="big" />
        </Grid.Column>
        <Grid.Column width={11} verticalAlign="middle">
          <Header as="h4">Outlook Calendar</Header>
        </Grid.Column>
        <Grid.Column width={4} textAlign="right">
          <Button
            primary
            icon
            labelPosition="left"
            onClick={signInWithOutlook}
          >
            <Icon name="microsoft" />
            Sign in with Outlook
          </Button>
        </Grid.Column>
      </Grid>
    </Segment>
  );
};

export default ExternalAccounts;
