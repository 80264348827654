import React from 'react';
import { Tab } from 'semantic-ui-react';
import { useHistory, useParams } from 'react-router-dom';
import ExternalAccounts from './ExternalAccounts';
import AvailableTimeBlocks from './AvailableTimeBlocks';

const Availability = ({ user }) => {
  const history = useHistory();
  const { tab } = useParams();

  const panes = [
    { menuItem: 'Daily Time Blocks', render: () => <AvailableTimeBlocks user={user} /> },
    { menuItem: 'External Calendars', render: () => <ExternalAccounts user={user} /> },
  ];

  const getActiveIndex = () => (tab === 'external_calendars' ? 1 : 0);

  const handleTabChange = (e, { activeIndex }) => {
    const newTab = activeIndex === 1 ? 'external_calendars' : 'available_time_blocks';
    history.push(`/settings/availability/${newTab}`);
  };

  return (
    <Tab
      menu={{ secondary: true, pointing: true }}
      panes={panes}
      activeIndex={getActiveIndex()}
      onTabChange={handleTabChange}
      id="availability-tab"
    />
  );
};

export default Availability;
