import React from 'react';
import StarRatings from 'react-star-ratings';
import {
  Popup,
  PopupContent,
} from 'semantic-ui-react';

const ExpertCardRating = ({ expert }) => {
  let rating = -1;
  let numStars = -1;
  const { teamData, numCompleted } = expert;

  if (teamData.length > 0) {
    rating = Math.round((10 * teamData.reduce((acc, t) => acc + t.avgRating * t.numEngagements, 0))
      / teamData.reduce((acc, t) => acc + t.numEngagements, 0)) / 10;
    numStars = Math.round(rating * 2) / 2;
  } else if (numCompleted > 0) {
    numStars = 5;
  }

  if (numCompleted > 0) {
    const color = rating > 0 ? '#D8A800' : 'lightgrey';
    return (
      <Popup
        trigger={(
          <div style={{ display: 'inline-block' }}>
            <StarRatings
              rating={numStars}
              numberOfStars={5}
              starRatedColor={color}
              starDimension="15px"
              starSpacing="1px"
            />
            <span style={{ color, fontSize: 15, fontWeight: 'bolder' }}>
              &#8202;
              &#40;
              {numCompleted}
              &#41;
            </span>
          </div>
        )}
        hoverable
        position="bottom center"
      >
        <PopupContent>
          {rating > 0 && (`Your team has worked with this expert ${numCompleted} time(s) and given an average rating of ${rating}`)}
          {rating <= 0 && (
            <>
              Your team has worked with this expert&nbsp;
              {numCompleted}
              &nbsp;time(s) but hasn&apos;t rated them yet.
            </>
          )}
        </PopupContent>
      </Popup>
    );
  }
  return null;
};

export default ExpertCardRating;
