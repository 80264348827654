import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import {
  Segment, Image, Grid,
} from 'semantic-ui-react';
import DataContext from '../../contexts/DataContext';
import Search from './Search';
import NavLinks from './NavLinks';
import UserMenu from './UserMenu';

const Navbar = () => {
  const { user, team } = useContext(DataContext);

  return (
    <Segment id="navbar">
      <Grid stackable columns={4}>
        <Grid.Column
          mobile={16}
          tablet={16}
          computer={3}
        >
          <Link to="/">
            <Image
              src={`${process.env.REACT_APP_AWS_S3_URL}/Dexterlogo.png`}
              size="small"
            />
          </Link>
        </Grid.Column>
        <Grid.Column
          mobile={16}
          tablet={16}
          computer={7}
          verticalAlign="bottom"
        >
          <Search />
        </Grid.Column>
        <Grid.Column
          mobile={8}
          tablet={8}
          computer={3}
          verticalAlign="middle"
        >
          <NavLinks />
        </Grid.Column>
        <Grid.Column
          mobile={8}
          tablet={8}
          computer={3}
          verticalAlign="bottom"
        >
          <UserMenu user={user} team={team} />
        </Grid.Column>
      </Grid>
    </Segment>
  );
};

export default Navbar;
