import React from 'react';
import {
  Divider, Form, TextArea, Input,
} from 'semantic-ui-react';
import FormFieldWithPopup from '../../FormFieldWithPopup';
import QualtricsSurveyAccess from '../QualtricsSurveyAccess';

const Details = ({ project, updateProject }) => {
  const {
    type, title, description, targetN,
  } = project;
  return (
    <div>
      <div>
        <strong className="project-title">Project Details</strong>
        <span className="note">* these fields will be shared with the experts</span>
      </div>
      <Divider />
      <Form.Input
        fluid
        required
        label="Project title"
        placeholder="Project title"
        value={title}
        onChange={(e, { value }) => updateProject({ title: value })}
      />
      <FormFieldWithPopup
        required
        control={TextArea}
        label="Describe your project"
        placeholder="Describe your project"
        popupContent={`
          Describe what you want to learn and accomplish to help experts determine
          if this project is a good fit for them.
          List the topics you want to discuss with them
          and if needed, include your target demographic and/or screening criteria
        `}
        value={description}
        onChange={(e, { value }) => updateProject({ description: value })}
      />
      {type === 'Survey' && (
        <>
          <FormFieldWithPopup
            control={Input}
            label="Target N"
            popupContent="How many responses do you intent to collect for this survey?"
            type="number"
            min={1}
            value={targetN}
            onChange={(e, { value }) => {
              // Prevent targetN to be NaN
              const n = value || 0;
              updateProject({ targetN: parseInt(n, 10) });
            }}
          />
          <br />
          {project.qualtricsSurvey && (
            <QualtricsSurveyAccess qualtricsSurvey={project.qualtricsSurvey} />
          )}
        </>
      )}
    </div>
  );
};

export default Details;
