import React from 'react';
import { Form, Divider, Header } from 'semantic-ui-react';

const Type = ({ project, updateProject }) => (
  <div>
    <Header className="project-title">Project Type</Header>
    <Divider />
    <p>Please select the type of project you would like to create</p>
    <Form.Dropdown
      selection
      fluid
      placeholder="Please select a project type"
      options={[
        { key: 'Interview', text: 'Interview', value: 'Interview' },
        { key: 'Survey', text: 'Online Survey', value: 'Survey' },
      ]}
      defaultValue={project.type}
      onChange={(e, { value }) => updateProject({ type: value })}
    />
  </div>
);

export default Type;
