import React, { useContext } from 'react';
import { Grid, Segment, Button } from 'semantic-ui-react';
import AuthContext from '../contexts/AuthContext';

const FirstTime = () => {
  const { logout } = useContext(AuthContext);

  return (
    <Grid container id="first-time">
      <Grid.Row>
        <Grid.Column>
          <Segment>
            <p>
              It looks like you are the first person from your team to
              sign up for Dexter. If you are new, become a client today by
              submitting a form
              {' '}
              <a
                href="https://www.dexterexperts.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'black', textDecoration: 'underline' }}
              >
                here
              </a>
            </p>
            <p>
              Is your team already on Dexter? Make sure you entered your
              email address correctly or get in touch with your team Admin
              to resend an invite to your email.
            </p>
            <p>
              Questions? Contact us at
              {' '}
              <a
                href="mailto:help@dexterexperts.com"
                style={{ color: 'black', textDecoration: 'underline' }}
              >
                help@dexterexperts.com
              </a>
            </p>
            <Button
              className="primary-btn"
              onClick={logout}
            >
              Back
            </Button>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default FirstTime;
