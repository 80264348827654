import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  Modal, Button, Icon, Dropdown, Grid,
} from 'semantic-ui-react';
import CopyLinkButton from '../CopyLinkButton';
import { UPDATE_LIST } from '../../queries/list';
import { LIST_PRIVACY_OPTIONS } from '../../constants/lists';

const ShareListButton = ({ list }) => {
  const listLink = `${process.env.REACT_APP_BASE_URL}/lists/${list.id}`;
  const [open, setOpen] = useState(false);
  const dropdownOptions = Object.keys(LIST_PRIVACY_OPTIONS).map((key) => ({
    key, text: LIST_PRIVACY_OPTIONS[key].dropdown, value: key,
  }));
  const [updateList] = useMutation(UPDATE_LIST);

  return (
    <>
      <Modal
        id="list-share-modal"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        size="tiny"
      >
        <Modal.Header>
          {`Share "${list.name}"`}
        </Modal.Header>
        <Modal.Content>
          <Grid verticalAlign="middle">
            <Grid.Row>
              <Grid.Column width={2}>
                <Icon name={LIST_PRIVACY_OPTIONS[list.privacy].icon} size="big" />
              </Grid.Column>
              <Grid.Column width={14}>
                <Dropdown
                  selection
                  value={list.privacy}
                  options={dropdownOptions}
                  onChange={(e, { value }) => updateList({
                    variables: { id: list.id, input: { privacy: value } },
                  })}
                />
                <div className="description">{LIST_PRIVACY_OPTIONS[list.privacy].description}</div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          {list.privacy === 'public' && (
            <CopyLinkButton content="Copy Link" icon="linkify" className="primary-btn" copyText={listLink} />
          )}
          <Button content="Done" onClick={() => setOpen(false)} />
        </Modal.Actions>
      </Modal>
      <Button
        as="a"
        icon="share alternate"
        content={list.privacy === 'private' ? 'Share List' : 'Sharing'}
        className={list.privacy === 'private' ? 'basic-btn' : 'primary-btn'}
        onClick={() => setOpen(true)}
      />
    </>
  );
};

export default ShareListButton;
