import { gql } from '@apollo/client';
import { EXPERT_BASIC_DATA } from './expert';

const LIST_BASIC_DATA = `
  id
  name
  privacy
  updatedAt
  experts {
    id
  }
`;

export const MY_LISTS = gql`
  query {
    myLists {
      ${LIST_BASIC_DATA}
      user {
        id
      }
    }
  }
`;

export const FOLLOWED_LISTS = gql`
  query {
    followedLists {
      ${LIST_BASIC_DATA}
      user {
        id
      }
    }
  }
`;

export const TEAM_LISTS = gql`
  query {
    teamLists {
      ${LIST_BASIC_DATA}
      user {
        id
      }
    }
  }
`;

export const LIST = gql`
  query List($id: String!) {
    list(id: $id) {
      ${LIST_BASIC_DATA}
      experts {
        ${EXPERT_BASIC_DATA}
      }
      user{
        id
        fullName
        membership {
          teamName
        }
      }
    }
  }
`;

export const CREATE_LIST = gql`
  mutation createList($input: ListInput!) {
    createList(input: $input) {
      ${LIST_BASIC_DATA}
    }
  }
`;

export const UPDATE_LIST = gql`
  mutation updateList($id: ID!, $input: ListInput!) {
    updateList(id: $id, input: $input) {
      ${LIST_BASIC_DATA}
    }
  }
`;

export const DELETE_LIST = gql`
  mutation deleteList($id: ID!) {
    deleteList(id: $id) {
      ${LIST_BASIC_DATA}
    }
  }
`;

export const ADD_EXPERT_TO_LIST = gql`
  mutation addExpertToList($listId: ID!, $expertId: ID!) {
    addExpertToList(listId: $listId, expertId: $expertId) {
      ${LIST_BASIC_DATA}
    }
  }
`;

export const DELETE_EXPERT_FROM_LIST = gql`
  mutation deleteExpertFromList($listId: ID!, $expertId: ID!) {
    deleteExpertFromList(listId: $listId, expertId: $expertId) {
      ${LIST_BASIC_DATA}
    }
  }
`;

export const FOLLOW_LIST = gql`
  mutation followList($listId: ID!) {
    followList(listId: $listId) {
      ${LIST_BASIC_DATA}
    }
  }
`;

export const UNFOLLOW_LIST = gql`
  mutation unfollowList($listId: ID!) {
    unfollowList(listId: $listId)
  }
`;

export const INVITE_LIST_TO_PROJECT = gql`
  mutation inviteListToProject($listId: ID!, $projectId: ID!) {
    inviteListToProject(listId: $listId, projectId: $projectId) {
      id
      numPendingInvites
      jobs {
        user {
          id
        }
      }
      team {
        id
        availableInvites
      }
    }
  }
`;

export default MY_LISTS;
