import React from 'react';
import { useHistory } from 'react-router-dom';
import { Label, Icon } from 'semantic-ui-react';

const SearchLabel = ({ query, textDisplay }) => {
  const history = useHistory();
  return (
    <Label
      size="small"
      as="a"
      className="search-label"
      onClick={() => {
        history.push({ pathname: '/search', search: `?query=${query}` });
      }}
    >
      {!textDisplay ? query : (
        <>
          <Icon name="search" />
          {textDisplay}
        </>
      )}
    </Label>
  );
};

export default SearchLabel;
