import React, { useState } from 'react';
import { Popup, Button } from 'semantic-ui-react';

const CopyLinkButton = ({
  content, className, copyText, copyPopMessage = 'Copied !', icon = null,
}) => {
  const [copyPopup, setCopyPopup] = useState('Copy link');
  const onCopy = async () => {
    await navigator.clipboard.writeText(copyText);
    setCopyPopup(copyPopMessage);
  };
  return (
    <Popup
      content={copyPopup}
      on="click"
      pinned
      trigger={(
        <Button
          content={content}
          className={className}
          onClick={(e) => {
            e.stopPropagation();
            onCopy(copyText);
          }}
          icon={icon}
        />
      )}
    />
  );
};

export default CopyLinkButton;
