import React from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  Segment, Header, Divider, Label, List, Button, Icon, Loader,
} from 'semantic-ui-react';
import { COUNT_EXPERTS } from '../../../queries/expert';
import FILTER_CATEGORIES from '../../../constants/filters';

const ExpertFilters = ({ project, categoryFilters, updateFilters }) => {
  const history = useHistory();
  const query = project.keywords.join(' ');
  const filters = Object.keys(categoryFilters).map((category) => ({
    categoryId: category,
    values: [categoryFilters[category]],
  }));
  const exclusions = [{ categoryId: 'projects', value: [project.id] }];
  const { loading, data } = useQuery(COUNT_EXPERTS, {
    variables: { query, filters, exclusions },
  });
  return (
    <Segment className="expert-filters">
      <Header as="h5">Filters</Header>
      <List horizontal>
        {project.keywords.map((keyword) => (
          <List.Item key={keyword}>
            <Label>{keyword}</Label>
          </List.Item>
        ))}
        {Object.keys(categoryFilters).map((category) => (
          <List.Item key={categoryFilters[category]} as="a" onClick={() => updateFilters(categoryFilters[category], category)}>
            <Label>
              <Icon name="remove" />
              {`${FILTER_CATEGORIES[category].title}: ${categoryFilters[category]}`}
            </Label>
          </List.Item>
        ))}
      </List>
      <Divider />
      <div className="footer-text">
        Found
        {loading ? <Loader active inline /> : <div className="total">{` ${data.search.total} `}</div>}
        matching additional experts
      </div>
      <Button
        className="primary-btn"
        onClick={() => history.push({
          pathname: '/search',
          search: `?query=${query}&${
            Object.keys(categoryFilters).map((c) => `${c}=${categoryFilters[c]}`).join('&')
          }&projects=${project.id}`,
        })}
      >
        Browse Experts
      </Button>
    </Segment>
  );
};

export default ExpertFilters;
