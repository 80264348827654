import React from 'react';
import { Segment, List, Divider } from 'semantic-ui-react';

const Activity = ({ project }) => (
  <Segment className="project-form">
    <strong className="project-title">Activity on this project</strong>
    <Divider />
    <List>
      {project.type === 'Interview' && (
        <List.Item>
          <strong>Scheduled Interviews: </strong>
          <span className="upcoming-stats">{project.numUpcomingMeetings}</span>
        </List.Item>
      )}
      <List.Item>
        <strong>
          {`Completed ${project.type}s:`}
        </strong>
        <span className="completed-stats">{project.numCompleted}</span>
      </List.Item>
    </List>
  </Segment>
);

export default Activity;
