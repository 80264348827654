import React, { useState, useContext } from 'react';
import {
  Modal, Icon, Button, Input, Header,
} from 'semantic-ui-react';
import CopyLinkButton from '../CopyLinkButton';
import DataContext from '../../contexts/DataContext';

const ProjectShareModal = ({ children, project }) => {
  const projectLink = `${process.env.REACT_APP_EXPERT_APP_URL}/landing/projects/${project.id}`;

  const { user } = useContext(DataContext);
  const [open, setOpen] = useState(false);
  const message = `Hi [Expert name],

Hope you're doing great.
I want to invite you to take part in an ${project.type === 'Interview' ? 'interview campaign' : 'online survey'} ("${project.title}") that I'm running on Dexter - an expert network platform. I believe your input is valuable to us.

Check out the project via this link (${projectLink}) to accept the invitation and ${project.type === 'Interview' ? 'schedule a convenient time for the interview' : 'take the survey'}.

Let me know if you have any questions. Thanks so much for considering this invitation!

Cheers,
${user.fullName || '[Your Name]'}`;

  return (
    <Modal
      closeIcon
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      trigger={children}
      id="project-share-modal"
    >
      <Modal.Header>Do you know someone who is a good fit?</Modal.Header>
      <Modal.Content>
        <p>
          Share the project link to invite them to review and join the project.
          Dexter will handle screening, scheduling and payments.
        </p>
        <div className="project-link-section">
          <Header>
            <Icon name="linkify" />
            <Header.Content>Directly share the project link</Header.Content>
          </Header>
          <div className="copy-section">
            <span className="form-label">Project link</span>
            <Input
              fluid
              label={(
                <CopyLinkButton
                  content="Copy"
                  className="basic-btn"
                  copyText={projectLink}
                />
              )}
              labelPosition="right"
              readOnly
              value={projectLink}
              className="form-input"
            />
          </div>
        </div>
        <div className="message-template-section">
          <Header>
            <Icon name="copy" />
            <Header.Content>Copy the invitation message containing the project link</Header.Content>
          </Header>
          <div className="copy-section">
            <span className="form-label">Invitation message template</span>
            <pre className="form-input">{message}</pre>
            <div className="copy-btn">
              <CopyLinkButton
                content="Copy Message Template"
                className="basic-btn"
                copyText={message}
              />
            </div>
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button className="primary-btn" onClick={() => setOpen(false)}>Done</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ProjectShareModal;
