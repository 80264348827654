import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  Accordion,
  Icon,
  Button,
  Message,
  Header,
  Transition,
} from 'semantic-ui-react';
import PreInterviewMaterial from './PreInterviewMaterial';
import ProjectCode from './ProjectCode';
import { UPDATE_PROJECT } from '../../../../queries/project';

const ProjectDetails = ({ project }) => {
  const [open, setOpen] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [settings, setSettings] = useState({
    preInterviewLink: project.preInterviewLink || '',
    projectCode: project.code || '',
  });
  const [updateProject, { loading, error }] = useMutation(UPDATE_PROJECT, {
    onCompleted: () => {
      setIsSaved(true);
      setTimeout(() => setIsSaved(false), 3000);
    },
  });
  return (
    <Accordion>
      <Accordion.Title
        active={open}
        onClick={() => setOpen(!open)}
      >
        Additional project details
        <Icon name="dropdown" />
      </Accordion.Title>
      <Accordion.Content active={open}>
        <div className="accordion-content">
          {error && <Message error={error !== null} content={error.message} />}
          {project.type === 'Interview' && <PreInterviewMaterial settings={settings} setSettings={setSettings} />}
          <ProjectCode settings={settings} setSettings={setSettings} />
          {isSaved && (
          <Transition visible={isSaved} animation="scale" duration={500}>
            <Header color="green">Successfully saved!</Header>
          </Transition>
          )}
          <Button
            className="primary-btn"
            loading={loading}
            disabled={loading || project.status === 'Completed'}
            onClick={() => updateProject({
              variables: {
                id: project.id,
                input: {
                  preInterviewLink: settings.preInterviewLink,
                  code: settings.projectCode,
                },
              },
            })}
          >
            Save
          </Button>
        </div>
      </Accordion.Content>
    </Accordion>
  );
};

export default ProjectDetails;
