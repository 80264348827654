import React, { useState, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { Modal, Button, Header } from 'semantic-ui-react';
import MessageContext from '../../contexts/MessageContext';
import { MANAGED_PROJECTS, UPCOMING_MEETINGS } from '../../queries/profile';
import { PROJECT_JOBS } from '../../queries/project';
import { RESCHEDULE_MEETING } from '../../queries/meeting';

const RescheduleMeeting = ({ job, children }) => {
  const [open, setOpen] = useState(false);
  const { setSuccessPopup, setErrorPopup } = useContext(MessageContext);

  const {
    id, project, user, interviewers,
  } = job;

  const [rescheduleMeeting, { loading }] = useMutation(RESCHEDULE_MEETING, {
    refetchQueries: [
      { query: UPCOMING_MEETINGS },
      { query: MANAGED_PROJECTS },
      { query: PROJECT_JOBS, variables: { id: project.id } },
    ],
    onCompleted: () => {
      setSuccessPopup({
        title: `Request to reschedule has been sent to ${user.fullName}`,
        content: `This event  will be removed from  your calendar and upcoming meetings.
          You will receive a notification when the expert reschedules a time.
          In the meantime, please head over to  the Settings > Availability page if you need to update your availability.`,
        onClose: () => setOpen(false),
      });
    },
    onError: (err) => {
      setErrorPopup({
        title: 'Failed to reschedule this meeting',
        content: err.message,
        onClose: () => setOpen(false),
      });
    },
  });

  const interviewerNames = interviewers.map((i) => i.fullName || i.email);

  return (
    <Modal
      closeIcon
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      trigger={children}
      className="setting-modal-layout"
    >
      <Modal.Header>Reschedule Meeting</Modal.Header>
      <Modal.Content>
        <Header>
          {`${project.title} with ${user.fullName} will be canceled and
            a rescheduling link will be sent to the expert`}
        </Header>
        <p>
          Please make sure to update your availability
          as soon as possible to reflect any changes to the available time slots
        </p>
        <Header as="h5">
          {`Warning! You are about to reschedule this interview with the following interviewer(s): ${interviewerNames.join(', ')}.
          Are you sure?`}
        </Header>
      </Modal.Content>
      <Modal.Actions>
        <Button className="basic-btn" onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button
          loading={loading}
          disabled={loading}
          className="primary-btn"
          onClick={() => rescheduleMeeting({ variables: { jobId: id } })}
        >
          Reschedule Meeting
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default RescheduleMeeting;
