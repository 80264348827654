import React from 'react';
import { Modal, Button, Header } from 'semantic-ui-react';

const ErrorPopup = ({
  onClose, title, header, content,
}) => (
  <Modal
    closeIcon
    open
    onClose={onClose}
    size="small"
  >
    {title && <Modal.Header>{title}</Modal.Header>}
    <Modal.Content>
      {header && <Header>{header}</Header>}
      {content && <p>{content}</p>}
    </Modal.Content>
    <Modal.Actions>
      <Button className="primary-btn" onClick={onClose}>Got it</Button>
    </Modal.Actions>
  </Modal>
);

export default ErrorPopup;
