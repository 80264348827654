import React, { useState, useContext } from 'react';
import { Form, List } from 'semantic-ui-react';
import DataContext from '../../../contexts/DataContext';

const MemberSelections = ({ selections, setSelections, exclusions }) => {
  const { team } = useContext(DataContext);
  const [query, setQuery] = useState('');
  const members = team.members.filter(
    (member) => member.user.email.toLowerCase().includes(query.toLowerCase()),
  );
  return (
    <Form>
      <Form.Input
        icon="search"
        value={query}
        onChange={(e, { value }) => setQuery(value)}
      />
      <List style={{ maxHeight: '400px', overflow: 'auto' }}>
        {members.map((member) => (
          <Form.Checkbox
            key={member.user.id}
            label={member.user.email}
            value={member.user.id}
            checked={selections.includes(member.user.id)}
            disabled={exclusions.includes(member.user.id)}
            onChange={(e, { checked, value }) => {
              if (checked) {
                setSelections(selections.concat(value));
              } else {
                setSelections(selections.filter((id) => id !== value));
              }
            }}
          />
        ))}
      </List>
    </Form>
  );
};

export default MemberSelections;
