import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import { useQuery } from '@apollo/client';
import { List } from 'semantic-ui-react';
import momentTZ from 'moment-timezone';
import Loading from '../../Loading';
import { EXPERT_JOBS_HISTORY } from '../../../queries/expert';
import DataContext from '../../../contexts/DataContext';

const RATING_COLOR = '#D8A800';

const JobList = ({ title, jobs }) => {
  const { user } = useContext(DataContext);

  const renderJobInfo = (job) => {
    const interviewers = job.interviewers?.map((interviewer) => {
      if (interviewer.id === user.id) {
        return 'you';
      }
      return interviewer.fullName || interviewer.email;
    }).join(', ');
    if (['Completed', 'Paid'].includes(job.overall_status)) {
      const time = momentTZ.tz(job.completedAt, user.timeZone).format('MM/DD/YY');
      if (job.expert_rating) {
        return (
          <>
            <StarRatings
              rating={job.expert_rating}
              numberOfStars={5}
              starRatedColor={RATING_COLOR}
              starDimension="15px"
              starSpacing="1px"
            />
            {interviewers && ` by ${interviewers}`}
            {` ${time}`}
            {job.expert_comment && (
              <>
                <br />
                <span>{`"${job.expert_comment}"`}</span>
              </>
            )}
          </>
        );
      }
      return <span>{`Completed ${time}`}</span>;
    }
    if (['Client Reschedule', 'Pending Scheduling'].includes(job.overall_status)) {
      return (
        <span>
          Sent
          {job.invitedAt && ` ${momentTZ.tz(job.invitedAt, user.timeZone).format('h:mm a, MM/DD/YY z')}`}
        </span>
      );
    }
    if (job.overall_status === 'Scheduled') {
      if (job.project.type === 'Interview') {
        const time = momentTZ.tz(job.meetingAt, user.timeZone).format('h:mm a, MM/DD/YY z');
        return <span>{`Scheduled for ${time} with ${interviewers}`}</span>;
      }
    }
    return null;
  };

  return (
    <div className="meeting-list">
      <div className="heading">
        <span>{`${title} (${jobs.length})`}</span>
      </div>
      {jobs.length > 0 && (
        <List className="content">
          {jobs.map((job) => (
            <List.Item key={job.id}>
              <Link to={`/projects/${job.project.id}`}>
                {job.project.title}
              </Link>
              <br />
              {renderJobInfo(job)}
            </List.Item>
          ))}
        </List>
      )}
    </div>
  );
};

const History = ({ expertId }) => {
  const { loading, data } = useQuery(EXPERT_JOBS_HISTORY, {
    variables: { id: expertId },
  });
  if (loading) return <Loading />;

  return (
    <div>
      <JobList title="Accepted" jobs={data.user.currentJobs} />
      <JobList title="Pending" jobs={data.user.pendingJobs} />
      <JobList title="Past" jobs={data.user.pastJobs} />
    </div>
  );
};

export default History;
