import React from 'react';
import { List, Grid } from 'semantic-ui-react';
import SearchExpertForm from '../SearchExpertForm';
import SearchLabel from '../SearchLabel';

const Search = () => (
  <Grid
    columns="equal"
    stackable
    className="nav-search"
  >
    <Grid.Column width={8}>
      <SearchExpertForm />
    </Grid.Column>
    <Grid.Column width={8} className="suggestion">
      <Grid.Row className="text">Try: </Grid.Row>
      <Grid.Row>
        <List horizontal>
          <List.Item>
            <SearchLabel query="liquid biopsy" />
          </List.Item>
          <List.Item>
            <SearchLabel query="single cell" />
          </List.Item>
          <List.Item>
            <SearchLabel query="CRISPR" />
          </List.Item>
        </List>
      </Grid.Row>
    </Grid.Column>
  </Grid>
);

export default Search;
